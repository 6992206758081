import React, { useState, useEffect, useMemo, useRef } from "react"
import "./imageTable.scss"
import axios from "axios"
import DataTable from "react-data-table-component"
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import { Link, useParams, useNavigate, useLocation } from "react-router-dom"
import ConfirmationModal from "../Modal/ConfirmationModal"
import { toast, ToastContainer, cssTransition } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import { CSVLink } from "react-csv"
import indexedDBService from "../../util/db"
import { Viewer } from "@react-pdf-viewer/core"
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout"
import { pageNavigationPlugin } from "@react-pdf-viewer/page-navigation"
import { zoomPlugin } from "@react-pdf-viewer/zoom"
import { saveAs } from 'file-saver'

const ImageTable = () => {
  const statusDropdownRef = useRef<any>(null)
  const navigate = useNavigate()
  const location = useLocation()
  const [currentPage, setCurrentPage] = useState<number>(
    parseInt(localStorage.getItem(location.pathname) ?? "1")
  )
  const [images, setImages] = useState<any[]>([])
  const [search, setSearch] = useState("")
  const [filteredImages, setFilteredImages] = useState([])
  const [startDate, setStartDate] = useState(null)
  const [filterValue, setFilterValue] = useState(
    localStorage.getItem("statusFilter") ?? "All"
  )
  const [selectedData, setSelectedData] = useState<any[]>([])
  const [toggleCleared, setToggleCleared] = useState(false)
  const [selectedDate, setSelectedDate] = useState(null)
  const [fetchAPI, setFetchAPI] = useState(false)
  const [apiData, setAPIData] = useState([])
  const [showModal, setShowModal] = useState(false)
  const [modalText, setModalText] = useState("")
  const [modalAction, setModalAction] = useState("")
  const [modalHeader, setModalHeader] = useState("")
  const [showInput, setShowInput] = useState(false)
  const [selectedStatus, setSelectedStatus] = useState(
    localStorage.getItem("statusFilter") ?? "All"
  )
  const [deleting, setDeleting] = useState(false)
  const [assignUser, setAssignUser] = useState(false)
  const [getUser, setGetUser] = useState([])
  const [userDetails, setUserDetails] = useState("")
  const [csvData, setCSVData] = useState([])
  const [isAdminLogin, setIsAdminLogin] = useState(false)
  const [selectedForExport, setSelectedForExport] = useState<any[]>([]) //Export those data which are only selected
  const [isZipData, setIsZipData] = useState(false)
  const [imagesOnFilter, setImagesOnFilter] = useState(false)
  const [fileName, setFileName] = useState("")
  const [zipApiData, setZipApiData] = useState({
    alt_text: "",
    asset_id: "",
    assigned_to: "",
    created_on: "",
    file_name: "",
    image_id: "",
    parent_id: "",
    status: "",
    thumbnail_file_path: ""
  })
  const [parentFile, setParentFile] = useState({
    parent_file_name: ""
  })
  const [pdfUrl, setPdfUrl] = useState<string | null>(null)
  const [resetSelection, setResetSelection] = useState(false)
  const [storeClientId, setStoreClientId] = useState<any>("")

  const [storeSelectedData, setStoreSelectedData] = useState<any[]>([])
  const [exporting, setExporting] = useState(false);
  const errorNotification = (errorMessage: string) => {
    toast.error(errorMessage, {
      position: toast.POSITION.TOP_CENTER,
      // eslint-disable-next-line
      icon: false,
      toastId: customId,
      containerId: "Error"
    })
  }
  useEffect(() => {
    // Fetch the PDF file in the background
    const fetchPDFFile = async () => {
      if (!pdfUrl) {
        return
      }

      const result = await indexedDBService.getPDFFromDB(pdfUrl)
      if (result) {
        return
      }

      try {
        console.log("fetching pdf")
        const response = await axios.get(pdfUrl!, {
          responseType: "arraybuffer" // Specify the response type as a blob
        })
        if (response.data) {
          const blob = response.data
          var binaryArray = blob // use UInt8Array for binary
          console.log("printing blob")
          console.log(blob)
          // Open an IndexedDB database

          await indexedDBService.addData(binaryArray, pdfUrl)
          console.log("File downloaded and stored in IndexedDB")
          // Optionally, you can store the PDF content in state or perform other actions here.
        } else {
          console.error(
            "Failed to fetch PDF file:",
            response.status,
            response.statusText
          )
        }
      } catch (error) {
        console.error("Error fetching PDF file:", error)
      }
    }
    fetchPDFFile()
  }, [pdfUrl])


  useEffect(() => {
    localStorage.setItem(location.pathname, currentPage.toString())
  }, [currentPage])

  const customId = "toast-id-dashboard"
  const assignId = "toast-id-assign"
  let zipAssetId = ""
  const { parentId } = useParams()
  const apiBasePath = process.env.REACT_APP_API_URL
  const basePath = process.env.REACT_APP_BASE_PATH
  const errorNotificaion = (errorMessage: string) => {
    toast.error(errorMessage, {
      position: toast.POSITION.TOP_CENTER,
      // eslint-disable-next-line
      icon: false,
      toastId: customId,
      containerId: "Error"
    })
  }
  const exportingNotification = (exportMessage: string) => {
    toast.success(exportMessage, {
      position: toast.POSITION.TOP_CENTER,
      // eslint-disable-next-line
      icon: false,
      toastId: customId,
      containerId: "Success",
      autoClose: false
    })
  }
  const Enter = cssTransition({
    enter: "fade-in",
    exit: "fade-out",
    appendPosition: false,
    collapse: true,
    collapseDuration: 300
  })

  const successAssignNotification = (successMessage: string) => {
    toast.success(successMessage, {
      position: toast.POSITION.TOP_CENTER,
      // eslint-disable-next-line
      icon: false,
      toastId: assignId,
      transition: Enter,
      containerId: "Success"
    })
  }

  const tableCustomStyles = {
    headCells: {
      style: {
        fontSize: "16px",
        fontWeight: "medium",
        color: "#151515",
        paddingLeft: "0 8px",
        justifyContent: "center",
        backgroundColor: "#F0F0F7"
      }
    }
  }

  const formatDataForCSV = (data: any) => {
    const formattedData = data.map((item: any) => ({
      Image_Id: item.image_id,
      FileName: item.file_name,
      AltText: item.alt_text,
      DateAdded: item.created_on,
      Status: getStatusText(item.status),
      ErrorMessage: item.error_message
    }))
    return formattedData
  }

  const getStatusText = (status: any) => {
    switch (status) {
      case "I":
        return "In progress"
      case "D":
        return "Draft"
      case "R":
        return "Reviewed"
      case "P":
        return "Pending"
      case "F":
        return "Failed"
      case "A":
        return "Approved"
      default:
        return ""
    }
  }
  useEffect(() => {
    isAdmin()
  }, [])

  useEffect(() => {
    const userToken = localStorage.getItem("user-token")
    if (userToken !== null)
      axios
        .get(`${apiBasePath}api/getUser`, {
          headers: {
            Authorization: `Bearer ${userToken}`
          }
        })
        .then((response) => {
          const data = response.data.data
          // console.log(data)
          if (response.status === 200) {
            setStoreClientId(data.client_id)
            localStorage.setItem("client_id", data.client_id)
            if (parentId === undefined) {
              getImages()
            }
          }
        })
        .catch((error) => {
          // console.log(error, "error")
          if (error.response.status === 401) {
            errorNotification(`${error.response.data.error}. You will now be logged out.`)
            setTimeout(() => {
              window.location.href = `${basePath}`;
              localStorage.clear()
            },3000)}
        })
  }, [])

  const getImages = async () => {
    let clientId: any = ""
    const userToken = localStorage.getItem("user-token")
    if (storeClientId !== "") {
      clientId = storeClientId
    } else {
      clientId = localStorage.getItem("client_id")
    }
    const passClientId = clientId
    var formData = new FormData()
    if (userToken !== null && passClientId !== null)
      formData.append("client_id", passClientId)

    axios
      .post(`${apiBasePath}api/getImageData`, formData, {
        headers: {
          Authorization: `Bearer ${userToken}`
        }
      })
      .then((response) => {
        const data = response.data.data
        if (response.status === 200) {
          if (data !== "" && data !== null) {
            setFetchAPI(true)
            setAPIData(data)
            const formattedData = formatDataForCSV(data)
            setCSVData(formattedData)
            if (isZipData === true) {
              setIsZipData(false)
            }
          } else if (data === null) {
            setTimeout(() => {
              setFetchAPI(true)
              setIsZipData(false)
              setImages([])
              setFilteredImages([])
              setParentFile({ parent_file_name: "" })
              setImagesOnFilter(true)
            }, 5000)
          } else {
            setFetchAPI(false)
          }
          setTimeout(() => {
            const rdt_TableHeader = document.querySelector(".rdt_TableHeader");
            rdt_TableHeader?.removeAttribute("role")
            rdt_TableHeader?.removeAttribute("aria-level")
            const headerCells = document.querySelectorAll(".rdt_TableCol_Sortable");
            headerCells.forEach((cells) => {
            if(cells.textContent == "Image" || cells.textContent == "File Name" || cells.textContent == "Alt Text"){
              cells.setAttribute("tabindex", "-1");
            }
            });
            [...document.querySelectorAll('[id^=row]')].forEach((row, index) => {
              row.setAttribute('tabindex', "0");
              row.addEventListener('keypress', (event:any)=>{
                  if(event.key == 'Enter'){
                    const row = data[index];
                    if (
                      row.image_id &&
                      row.file_name.includes(".zip") &&
                      row.status !== "P" &&
                      row.status !== "F"
                    ) {
                      navigate(`../${basePath}/Dashboard/${row.asset_id}`)
                      setZipApiData(row)
                      setIsZipData(true)
                      setToggleCleared(true) // set the selected rows state to true
                      setResetSelection(true) // sets the resetSelection flag to true
                      setSelectedForExport([])
                      zipAssetId = row.asset_id
                      getZipImages(zipAssetId)
                      // console.log("ZIP FILE")
                    } else if (
                      row.image_id  &&
                      row.file_name.includes(".epub") &&
                      row.status !== "P" &&
                      row.status !== "F"
                    ) {
                      navigate(`../${basePath}/Dashboard/${row.asset_id}`)
                      setToggleCleared(true) // set the selected rows state to true
                      setResetSelection(true) // sets the resetSelection flag to true
                      setSelectedForExport([])
                    } else if (
                      row.image_id &&
                      row.file_name.includes(".pdf") &&
                      row.status !== "P" &&
                      row.status !== "F"
                    ) {
                      navigate(`../${basePath}/Dashboard/${row.asset_id}`)
                      setToggleCleared(true) // set the selected rows state to true
                      setResetSelection(true) // sets the resetSelection flag to true
                      setSelectedForExport([])
                    } else if (
                      row.image_id &&
                      !row.file_name.includes(".zip") &&
                      !row.file_name.includes(".epub") &&
                      !row.file_name.includes(".pdf") &&
                      row.status !== "F"
                    ) {
                      navigate(`../${basePath}/altTextDetails/${row.asset_id}`)
                    }
                  }
              })
          })
          }, 2000);
        }
      })
      .catch((error) => {
        setFetchAPI(true)
      })
  }

  // const filterImages = (imagesData: any, filter: any, date: Date | null) => {
  //   let filtered = imagesData

  //   if (filter !== "All") {
  //     setSelectedForExport([])
  //     setImagesOnFilter(true)
  //     filtered = filtered.filter(
  //       (image: { status: any }) => image.status === filter
  //     )
  //   }

  //   if (date) {
  //     toast.dismiss()
  //     setSelectedForExport([])
  //     setIsZipData(false)
  //     setImagesOnFilter(true)
  //     const selectedDate = new Date(date.setHours(0, 0, 0, 0))
  //     filtered = filtered.filter((image: { created_on: any }) => {
  //       const imageDate = new Date(image.created_on)
  //       const imageDateWithoutTime = new Date(imageDate.setHours(0, 0, 0, 0))
  //       return imageDateWithoutTime.getTime() === selectedDate.getTime()
  //     })
  //   }
  //   setFilteredImages(filtered)
  // }

  // Update filterImages function to accept searchText parameter
  const filterImages = (imagesData: any, filter: any, date: Date | null, searchQuery: string) => {
    let filtered = imagesData;
  
    // Apply status filter
    if (filter !== "All") {
      setSelectedForExport([])
      setImagesOnFilter(true)
      filtered = filtered.filter((image: { status: any }) => image.status === filter);
    }
  
    // Apply date filter
    if (date) {
      toast.dismiss()
      setSelectedForExport([])
      setIsZipData(false)
      setImagesOnFilter(true)
      const selectedDate = new Date(date.setHours(0, 0, 0, 0));
      filtered = filtered.filter((image: { created_on: any }) => {
        const imageDate = new Date(image.created_on);
        const imageDateWithoutTime = new Date(imageDate.setHours(0, 0, 0, 0));
        return imageDateWithoutTime.getTime() === selectedDate.getTime();
      });
    }
  
    // Apply search filter
    if (searchQuery) {
      const escapedSearch = escapeRegExp(searchQuery.toLowerCase());
      filtered = filtered.filter((image: { file_name: string, alt_text: string }) => {
        if (image.alt_text !== null) {
          const fileNameMatch = image.file_name.toLowerCase().match(escapedSearch);
          const altTextMatch = image.alt_text.toLowerCase().match(escapedSearch);
          return fileNameMatch || altTextMatch;
        }
        return false; // Exclude images with null alt_text
      });
    }
    setFilteredImages(filtered);
  };
  
  const handleSearch = (searchQuery: string) => {
    setSearch(searchQuery);
    filterImages(images, selectedStatus, selectedDate, searchQuery);
  };
  

  const columns = [
    // {
    //   name: "Assign",
    //   cell: (row: any) => {
    //     if (isAdminLogin && row.assigned_to) {
    //       return (
    //         <div
    //           aria-hidden="true"
    //           aria-label={`Assigned to: ${row.assigned_to.name}`}
    //         >
    //           <i
    //             data-tag="allowRowEvents"
    //             className="personAssignIcon"

    //             title={`Assigned to: ${row.assigned_to.name}`}
    //           ></i>
    //         </div>

    //       );
    //     } else {
    //       return null;
    //     }
    //   },
    //   width: "40px",
    //   ariaLabel: "Assign",
    //   ariaHidden: true,
    // },
    {
      name: <h2><span className="dataTable-header">Image</span></h2>,
      cell: (row: any) => {
        if (row.file_name.includes(".zip")) {
          return (
            <div className="thumbWrapper">
              <div className="thumbnail-wrapper">
                <div className="thumbnail-container">
                  <img
                    className="zip-icon"
                    src={require("../../assets/images/zip-icon.png")}
                    width={50}
                    alt="Zip icon"
                  ></img>
                </div>
              </div>
              <span data-tag="allowRowEvents" className="column-alt-text image-fileName" aria-label={`file name: ${row.file_name}`}>
                {row.file_name}
              </span>
              <span data-tag="allowRowEvents" className="column-alt-text childCount">
                  Image Count: {row.child_count}
              </span>
            </div>
          );
        } else if (row.file_name.includes(".epub")) {
          return (
            <div className="thumbWrapper">
              <div className="thumbnail-wrapper">
                <div className="thumbnail-container">
                  <img
                    className="epub-icon"
                    src={require("../../assets/images/epub-icon.png")}
                    width={50}
                    alt="E pub icon"
                  ></img>
                </div>
              </div>
              <span data-tag="allowRowEvents" className="column-alt-text image-fileName" aria-label={`file name: ${row.file_name}`}>
                {row.file_name}
              </span>
              <span data-tag="allowRowEvents" className="column-alt-text childCount">
                  Image Count: {row.child_count}
              </span>
            </div>
          );
        } else if (row.file_name.includes(".pdf")) {
          return (
            <div className="thumbWrapper">
              <div className="thumbnail-wrapper">
                <div className="thumbnail-container">
                  <img
                    className="pdf-icon"
                    src={require("../../assets/images/pdf-icon.png")}
                    width={50}
                    alt="PDF icon"
                  ></img>
                </div>
              </div>
              <span data-tag="allowRowEvents" className="column-alt-text image-fileName" aria-label={`file name: ${row.file_name}`}>
                {row.file_name}
              </span>
              <span data-tag="allowRowEvents" className="column-alt-text childCount">
                Image Count: {row.child_count}
              </span>
            </div>
          );
        } else {
          return (
            <div className="thumbWrapper">
              <div className="thumbnail-wrapper">
                <div className="thumbnail-container">
                  <img
                    className="thumbnail-image"
                    src={row.original_file_path}
                    width={200}
                    alt="Snapshot of the image"
                  ></img>
                </div>
              </div>
              <span data-tag="allowRowEvents" className="column-alt-text image-fileName" aria-label={`file name: ${row.file_name}`}>
                {row.file_name}
              </span>
            </div>
          );
        }
      },
      selector: (row: any) => row.original_file_path,
      width: "20%",
      headerClass: "custom-column",
      ariaLabel: "Image",
      sortable: true,
      sortFunction: (a: any, b: any) => {
        return a.file_name.localeCompare(b.file_name);
      },
    },
    // {
    //   name: <h2><span className="dataTable-header">File Name</span></h2>,
    //   cell: (row: any) => (
    //     <span data-tag="allowRowEvents" className="column-alt-text" aria-label={`file name: ${row.file_name}`}>
    //       {row.file_name}
    //     </span>
    //   ),
    //   width: "245px",
    //   ariaLabel: "File Name",
    //   sortable: true,
    //   sortFunction: (a: any, b: any) => {
    //     return a.file_name.localeCompare(b.file_name);
    //   },
    // },
    {
      name: <h2><span className="dataTable-header">Alt Text</span></h2>,
      cell: (row: any) => {
        if (row.alt_text) {
          return (
            <span
              data-tag="allowRowEvents"
              className="column-alt-text"
              aria-label={`Alt text: ${row.alt_text}`}
            >
              {row.alt_text}
            </span>
          );
        }
        return null;
      },
      selector: (row: any) => row.alt_text,
      width: "50%",
      ariaLabel: "Alt Text",
    },
    {
      name: <h2><span className="dataTable-header">Date Added</span></h2>,
      selector: (row: any) => row.created_on,
      minWidth: "12%",
      maxWidth: "14%",
      sortable: true,
      sortFunction: (a: any, b: any) => {
        const dateA = new Date(a.created_on);
        const dateB = new Date(b.created_on);
        return dateA.getTime() - dateB.getTime();
      },
      cell: (row: any) => {
        const formattedDate = new Date(row.created_on).toLocaleDateString(
          "en-US",
          {
            month: "short",
            day: "2-digit",
            year: "numeric",
          }
        );
        return (
          <div
            className="dataTable-date-column"
            aria-label={`Date added: ${formattedDate}`}
            tabIndex={0}
          >
            {formattedDate}
          </div>
        );
      },
      ariaLabel: "Date Added",
    },
    {
      name: <h2><span className="dataTable-header">Status</span></h2>,
      cell: (row: any) => {
        if (row.status) {
          if (row.error_message !== null) {
            return (
              <div
                className="failed-error"
                data-tooltip={row.error_message}
                aria-label={`Error: ${row.error_message}`}
              >
                <i className="failed-error-icon"></i>
                <span className="failed-text">Failed</span>
              </div>
            );
          } else {
            return (
              <div
                className={`fileStatus ${getStatusClassName(row.status)}`}
                aria-label={`Status: ${getStatusText(row.status)}`}
              >
                {getStatusText(row.status)}
              </div>
            );
          }
        } else {
          if (row.error_message !== null) {
            return (
              <div
                className="failed-error"
                data-tooltip={row.error_message}
                aria-label={`Error: ${row.error_message}`}
              >
                <i className="failed-error-icon"></i>
                <span className="failed-text">Failed</span>
              </div>
            );
          }
        }
        return null;
      },
      sortable: true,
      minWidth: "12%",
      maxWidth: "14%",
      sortFunction: (a: any, b: any) => {
        const statusA = getStatusText(a.status);
        const statusB = getStatusText(b.status);
        return statusA.localeCompare(statusB);
      },
      ariaLabel: "Status",
    },
  ];
  

  const getStatusClassName = (status: any) => {
    switch (status) {
      case "I":
        return "progress-btn"
      case "D":
        return "draft-btn"
      case "R":
        return "reviewed-btn"
      case "P":
        return "pending-btn"
      case "F":
        return "failed-btn"
      default:
        return ""
    }
  }

  const isAdmin = async () => {
    const userToken = localStorage.getItem("user-token")
    if (userToken !== null)
      axios
        .get(`${apiBasePath}api/admin/checkUsersRoleData`, {
          headers: {
            Authorization: `Bearer ${userToken}`
          }
        })
        .then((response) => {
          // console.log(response.data)

          const data = response.data.data
          if (response.status === 200) {
            if (data.usertype === "Admin" || data.usertype === "Super admin") {
              setIsAdminLogin(true)
            }
          }
        })
        .catch((error) => {
          // console.log(error)
        })
  }

  useEffect(() => {
    setImages(apiData);
    setFilteredImages(apiData);
    filterImages(apiData, filterValue, selectedDate, search);
  }, [apiData, search, selectedStatus, selectedDate]);

  function escapeRegExp(string: any) {
    // The RegExp escapes special characters in a given string
    return string.replace(/[.*+?^${}()|[\]\\]/g, "\\$&")
  }

  useEffect(() => {
    const escapedSearch: any = escapeRegExp(search)
    const result: any = images.filter((image) => {
      if (image.alt_text !== null) {
        toast.dismiss()
        const fileNameMatch = image.file_name
          .toLowerCase()
          .match(escapedSearch.toLowerCase())
        const altTextMatch = image.alt_text
          .toLowerCase()
          .match(escapedSearch.toLowerCase())
        return fileNameMatch || altTextMatch
      }
    })
    filterImages(images, filterValue, selectedDate, search);
  }, [search])

  useEffect(() => {
    localStorage.setItem("statusFilter", selectedStatus)
  }, [selectedStatus])

  const handleRowSelected = (state: any) => {
    toast.dismiss()
    setFileName("")
    if (!resetSelection) {
      setSelectedData(state.selectedRows)
    }
    if (state.selectedCount > 0) {
      setStoreSelectedData(state.selectedRows)
    } else {
      setStoreSelectedData([])
    }
  }

  const handleStatusFilter = (selectedFilter: string) => {
    toast.dismiss()
    setSelectedStatus(selectedFilter);
    setFilterValue(selectedFilter);
    filterImages(images, selectedFilter, selectedDate, search);
    statusDropdownRef.current.focus();
  };

  const handleDelete = () => {
    if (selectedData.length > 0) {
      const fileName = selectedData[0].file_name
      setModalAction("Delete")
      setModalHeader(
        `<i class="deleteConfIcon"></i><span class="delete-header">Delete File</span>`
      )
      setModalText(
        `<span class="deleteText">Are you sure you want to delete ${
          selectedData.length > 1
            ? `these <span class="delete-file-name">${selectedData.length}</span> files`
            : `<span class="delete-file-name">${fileName}</span>`
        } ? This action cannot be undone.</span>`
      )
      setShowInput(false)
      setShowModal(true)
      setTimeout(() => {
        const cancelButton = document.querySelector(
          ".cancel-button"
        ) as HTMLButtonElement | null
        if (cancelButton) {
          cancelButton.focus() // Autofocus the Cancel button when the Delete button is clicked
        }
      }, 0)
      setSelectedForExport([])
    } else {
      toast.dismiss()
      errorNotificaion("Please select an image to delete.")
    }
  }

  const handleAssign = () => {
    if (selectedData.length > 0) {
      setModalAction("Assign")
      setModalHeader(`<span class="assign-header">Assign</span>`)
      setModalText("Assign to")

      const unassignedSelectedData = selectedData.filter(selectedItem => {
        return !apiData.some((data: any) => data.image_id === selectedItem.image_id && data.assigned_to !== null);
      });
      if (unassignedSelectedData.length === 0) {
        errorNotificaion("User is already assigned")
        return;
      }
      setShowInput(true)
      setShowModal(true)
    } else {
      toast.dismiss()
      errorNotificaion("Please select an image to assign.")
    }
  }

  const handleModalConfirm = async () => {
    if (modalAction === "Delete") {
      setDeleting(true)

      const imageIds = selectedData.map((data) => data.image_id)
      const requestData = { image_id: imageIds }

      axios
        .post(`${apiBasePath}api/deleteImages`, requestData, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("user-token")}`
          }
        })
        .then((response) => {
          // Handle the response if needed
          setDeleting(false)
          setShowModal(false)
          if (selectedData.length === csvData.length) {
            // for deleting all the data in dashboard, No files to show page displays
            setFetchAPI(true)
            setImages([])
          }
          if (parentId === undefined) {
            getImages()
          } else {
            getZipImages(parentId)
          }
          setToggleCleared(true) // set the selected rows state to true
          setResetSelection(true) // sets the resetSelection flag to true
          successAssignNotification("Image(s) deleted successfully")
        })
        .catch((error:any) => {
          // Handle the error if needed
          setShowModal(false)
          setToggleCleared(true) // set the selected rows state to true
          setResetSelection(true) // sets the resetSelection flag to true
          errorNotificaion(error.response.data.error)
          setDeleting(false)
        })
    } else if (modalAction === "Assign") {
      setAssignUser(true)
      const emailIdInput = document.querySelector(
        ".assignModalBody"
      ) as HTMLInputElement | null
      const emailId = emailIdInput?.getElementsByTagName("input")[0].value

      const assignedUsers = getUser // Replace with the actual assigned users
      const imageIds = selectedData.map((data) => data.image_id)
      const matchedUserDetail: any = assignedUsers.find(
        (userDetail: any) => userDetail.email === emailId
      )
      const requestData = {
        assigned_users: matchedUserDetail.id,
        image_id: imageIds
      }

      if (matchedUserDetail) {
        try {
          const response = await axios.post(
            `${apiBasePath}api/assignImages`,
            requestData,
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("user-token")}`
              }
            }
          )

          // Reset state and close the modal
          setAssignUser(false)
          setShowModal(false)
          if (parentId === undefined) {
            getImages()
          } else {
            getZipImages(parentId)
          }
          setToggleCleared(true) // set the selected rows state to true
          setResetSelection(true) // sets the resetSelection flag to true
          successAssignNotification("File(s) assigned successfully")
        } catch (error) {
          // console.log(error) // Handle the error if needed
        }
      }
    }
  }

  useEffect(() => {
    // When the resetSelection flag is true, reset the selectedData state to an empty array
    if (resetSelection) {
      setSelectedData([])
      setResetSelection(false) // Reset the resetSelection flag back to false
    }
    // Check if rows have been cleared (deselected)
    if (toggleCleared) {
      setSelectedData([]) // Clear the selected rows state
      setToggleCleared(false) // Reset the toggleCleared state back to false
    }
  }, [resetSelection])

  const handleModalCancel = () => {
    setShowModal(false)
  }

  useEffect(() => {
    if (parentId) {
      setIsZipData(true)
      getZipImages(parentId)
    }
  }, [parentId])

  const handleRowClicked = (row: any) => {
    toast.dismiss()
    const updatedData = images.map((item) => {
      if (
        row.image_id === item.image_id &&
        row.file_name.includes(".zip") &&
        row.status !== "P" &&
        row.status !== "F"
      ) {
        navigate(`../${basePath}/Dashboard/${row.asset_id}`)
        setZipApiData(row)
        setIsZipData(true)
        setToggleCleared(true) // set the selected rows state to true
        setResetSelection(true) // sets the resetSelection flag to true
        setSelectedForExport([])
        zipAssetId = row.asset_id
        getZipImages(zipAssetId)
        // console.log("ZIP FILE")
      } else if (
        row.image_id === item.image_id &&
        row.file_name.includes(".epub") &&
        row.status !== "P" &&
        row.status !== "F"
      ) {
        navigate(`../${basePath}/Dashboard/${row.asset_id}`)
        setToggleCleared(true) // set the selected rows state to true
        setResetSelection(true) // sets the resetSelection flag to true
        setSelectedForExport([])
      } else if (
        row.image_id === item.image_id &&
        row.file_name.includes(".pdf") &&
        row.status !== "P" &&
        row.status !== "F"
      ) {
        navigate(`../${basePath}/Dashboard/${row.asset_id}`)
        setToggleCleared(true) // set the selected rows state to true
        setResetSelection(true) // sets the resetSelection flag to true
        setSelectedForExport([])
      } else if (
        row.image_id === item.image_id &&
        !row.file_name.includes(".zip") &&
        !row.file_name.includes(".epub") &&
        !row.file_name.includes(".pdf") &&
        row.status !== "F"
      ) {
        navigate(`../${basePath}/altTextDetails/${row.asset_id}`)
      }
    })
  }

  const getZipImages = async (parentId: any) => {
    const userToken = localStorage.getItem("user-token")
    const clientId = localStorage.getItem("client_id")
    var formData = new FormData()
    if (userToken !== null && clientId !== null && parentId !== null)
      formData.append("client_id", clientId)
    formData.append("asset_id", parentId)
    axios
      .post(`${apiBasePath}api/getImageData`, formData, {
        headers: {
          Authorization: `Bearer ${userToken}`
        }
      })
      .then((response) => {
        // console.log(response.data.data[0].asset_id)

        const data = response.data.data
        const responseData = response.data
        // console.log(data, "ZIP DATA")
        if (response.status === 200) {
          if (data !== "" && data !== null) {
            setImages(data)
            setFilteredImages(data)
            setSelectedData([])
            setFetchAPI(true)
            setAPIData(data)
            setIsZipData(true)
            setParentFile(responseData)
            const formattedData = formatDataForCSV(data)
            setCSVData(formattedData)
            // console.log(`DEBUG: ${response.data.parent_file_path}`)
            setPdfUrl(response.data.parent_file_path)
          } else {
            setFetchAPI(true)
            setImages([])
            setFilteredImages([])
            setParentFile(responseData)
          }
          setTimeout(() => {
            const headerCells = document.querySelectorAll(".rdt_TableCol_Sortable");
            headerCells.forEach((cells) => {
            if(cells.textContent == "Image" || cells.textContent == "File Name" || cells.textContent == "Alt Text"){
              cells.setAttribute("tabindex", "-1");
            }
            });
            [...document.querySelectorAll('[id^=row]')].forEach((row, index) => {
              row.setAttribute('tabindex', "0");
              row.addEventListener('keypress', (event:any)=>{
                  if(event.key == 'Enter'){
                    const row = data[index];
                    if (
                      row.image_id &&
                      row.status !== "F"
                    ) {
                      navigate(`../${basePath}/altTextDetails/${row.asset_id}`)
                    }
                  }
              })
          })
          }, 2000);
        }
      })
      .catch((error) => {
        setFetchAPI(true)
        // console.log(error)
      })
  }

  const upload = () => {
    navigate(`../${basePath}/Upload`)
  }

  const getUsers = () => {
    const userToken = localStorage.getItem("user-token")
    if (userToken !== null)
      axios
        .get(`${apiBasePath}api/usersList`, {
          headers: {
            Authorization: `Bearer ${userToken}`
          }
        })
        .then((response) => {
          const data = response.data.data
          const userDetails = response.data.data.map((user: any) => {
            return user.email
          })

          if (response.status === 200) {
            if (data !== "") {
              setGetUser(data)
              // setUserDetails(userDetails)
            }
          }
        })
        .catch((error) => {
          // console.log(error)
        })
  }

  const getUsersForAssignment = () => {
    const userToken = localStorage.getItem("user-token")
    if (userToken !== null)
      axios
        .get(`${apiBasePath}api/usersListForAssignment`, {
          headers: {
            Authorization: `Bearer ${userToken}`
          }
        })
        .then((response) => {
          const data = response.data.data
          const userDetails = response.data.data.map((user: any) => {
            return user.email
          })

          if (response.status === 200) {
            if (data !== "") {
              setGetUser(data)
              setUserDetails(userDetails)
            }
          }
        })
        .catch((error) => {
          // console.log(error)
        })
  }

  useEffect(() => {
    getUsers()
    getUsersForAssignment()
  }, [])

  const exportSelectedData = () => {
    toast.dismiss()
    setCSVData([]);
    if (storeSelectedData.length === 0) {
      errorNotificaion("Please select a file to export.");
      return;
    }
    // Set exporting to true to disable export button
    setExporting(true);
    // Call your function to get data formatted for CSV
    getDataToExportCSV(storeSelectedData);
  }
  const handleChangeRaw = (date: any, event: any) => {
    if (date.type !== "click") {
      const inputValue = date.currentTarget.value
      if(inputValue){
        const sanitizedValue = inputValue.replace(/[^0-9/]/g, "").substr(0, 10)
        date.currentTarget.value = sanitizedValue
      }
    }
  }

  const handleRefresh = () => {
    // handles component refresh based on isZipData true or false
    toast.dismiss()
    setToggleCleared(true) // set the selected rows state to true
    setResetSelection(true) // sets the resetSelection flag to true
    setExporting(false)
    setStoreSelectedData([])
    if (isZipData) {
      getZipImages(parentId)
    } else {
      getImages()
    }
  }
  const handleDatePickerToggle = (opened: any) => {
    const announcement = opened ? 'Date picker opened' : 'Date picker closed';
    announceToScreenReader(announcement);
  };
  const announceToScreenReader = (message: any) => {
    const liveRegion = document.getElementById('aria-live-region');

    if (liveRegion) {
      liveRegion.innerText = message;
      // Ensure screen reader announces the change by updating aria-live value
      liveRegion.setAttribute('aria-live', 'assertive');
      setTimeout(() => {
        liveRegion.setAttribute('aria-live', 'off');
      }, 100);
    }
  };

  const getDataToExportCSV = async (data: any) => {
    const userToken = localStorage.getItem("user-token")
    const now = new Date()
    let fileName = ""
    const formattedTime = now.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', second: '2-digit' })
    const storeImageIds = data.map((item: any) => item.image_id)
    if (storeImageIds.length === 1 && (data[0].file_name.includes(".zip") || 
      data[0].file_name.includes(".epub") || data[0].file_name.includes(".pdf"))) {
        fileName = `${data[0].file_name.replace('.pdf', '')} - ${formattedTime}`
    } else {
      fileName = `ImageData - ${formattedTime}`
    }
    const requestParams = {
      image_ids: storeImageIds
    }
    // Show a toast message indicating that the data is being exported
    exportingNotification("Exporting data. Please wait...");
    setTimeout(() => {
      if (userToken !== null)
        axios
          .post(`${apiBasePath}api/exportCsv`,
            requestParams, {
            headers: {
              Authorization: `Bearer ${userToken}`
            }
          })
          .then((response) => {
            const data = response.data
            if (response.status === 200 && data !== "") {
              toast.dismiss();
              const blob = new Blob([data], { type: 'text/csv;charset=utf-8' });

              saveAs(blob, `${fileName}.csv`);
              successAssignNotification("Data exported successfully");
              // setToggleCleared(true) // set the selected rows state to true
              // setResetSelection(true) // sets the resetSelection flag to true
              // setSelectedForExport([])
              // setStoreSelectedData([])
              setExporting(false);
              setFileName("")
            }
          })
          .catch((error) => {
            toast.dismiss()
            console.log(error)
            setExporting(false);
            errorNotificaion(error.message);
          })
        }, 2000);
      }
  // console.log(selectedForExport, "selectedForExport")
  const rowDisabledCriteria = (row:any) => 
    row?.status === "P" || row?.status === "I"
  return (
    <div id="mainContent" className="dashboardPage container mt-4">
      {isZipData ? (
        <>
          <Link
            type="button"
            className="btn btn-primary dashboardPath mb-1 me-1"
            to={`${basePath}/Dashboard`}
            onClick={() => getImages()}
          >
            <span className="dashboardLinkText">Dashboard </span>
          </Link>
          <span>/</span>
          <span className="slashPath ms-1">{parentFile.parent_file_name}</span>
          <div>
            <Link
              type="button"
              className="btn btn-primary backButton ms-3"
              to={`${basePath}/Dashboard`}
              onClick={() => getImages()}
            >
              <i className="backArrow"></i>
              <span>{parentFile.parent_file_name}</span>
            </Link>
          </div>
        </>
      ) : (
        <div className="dashboardTitle container d-flex flex-column align-items-start"
        role="heading" aria-level={1}>
          Dashboard
        </div>
      )}
      {!fetchAPI && <div className="custom-loader"></div>}
      {fetchAPI && (
        <>
          {(images === undefined || images.length === 0) && storeClientId === '' ? (
            <>
              <div className="dashboard-upload-container container mx-auto d-flex align-items-center mt-1">
                <div className="upload-inner-container inner-container mx-auto">
                  {isAdminLogin ? (
                    <>
                      <div className="noFiles-instruction mb-2">
                        No Files to Show
                      </div>
                      <div className="files-upload-instruction mb-2">
                        Kindly upload your images / files to generate the Alt
                        Text
                      </div>
                      <button className="upload-btn mb-2" onClick={upload}>
                        Upload
                      </button>
                    </>
                  ) : (
                    <div className="noFiles-instruction mb-2">
                      No Files have been Assigned
                    </div>
                  )}
                </div>
              </div>
            </>
          ) : (
            <>
              <div className="searchText container d-flex flex-wrap mb-2">
                <div className="search col-md-4 col-lg-3">
                  <div className="input-icons">
                    <input
                      type="text"
                      className="inputIcon form-control mb-2"
                      placeholder="Search"
                      value={search}
                      onChange={(e) => handleSearch(e.target.value)}
                    />
                  </div>
                </div>
                <div className="statusDD col-md-3 col-lg-2">
                  <div className="dropdown-status mb-2">
                    <button
                      className="btn btn-secondary dropdown-toggle"
                      type="button"
                      role="combobox"
                      id="dropdownMenuButton1"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                      ref={statusDropdownRef}
                      aria-label="status-dropdown"
                    >
                      <span className="status">Status: </span>
                      <span className="selected-status">
                        {selectedStatus === "P"
                          ? "Pending"
                          : selectedStatus === "I"
                          ? "In Progress"
                          : selectedStatus === "D"
                          ? "Draft"
                          : selectedStatus === "R"
                          ? "Reviewed"
                          : selectedStatus === "F"
                          ? "Failed"
                          : selectedStatus === "A"
                          ? "Approved"
                          : "All"}
                      </span>
                      {selectedStatus !== "All" && (
                        <span className="tick-mark"></span>
                      )}
                      {/* <span className="all">{filterValue === "D" ? "Draft" : filterValue === "R" ? "Reviewed" : 'All'}</span> */}
                    </button>
                    <ul
                      className="dropdown-menu"
                      aria-labelledby="dropdownMenuButton1"
                    >
                      <li>
                        <a
                          className={`dropdown-item${
                            selectedStatus === "All" ? " active" : ""
                          }`}
                          role="option"
                          data-index="1"
                          tabIndex={0}
                          aria-label="All 1 out of 7"
                          aria-selected={selectedStatus === "All"}
                          onClick={() => handleStatusFilter("All")}
                          onKeyDown={(e) => {
                            if (e.key === "Enter") {
                              handleStatusFilter("All");
                            }
                          }}
                        >
                          All
                          {selectedStatus === "All" && (
                            <span className="tick-mark"></span>
                          )}
                        </a>
                      </li>
                      {isAdminLogin ? (
                        <>
                          <li>
                            <a
                              className={`dropdown-item${
                                selectedStatus === "P" ? " active" : ""
                              }`}
                              role="option"
                              data-index="2"
                              tabIndex={0}
                              aria-label="pending 2 out of 7"
                              aria-selected={selectedStatus === "P"}
                              onClick={() => handleStatusFilter("P")}
                              onKeyDown={(e) => {
                                if (e.key === "Enter") {
                                  handleStatusFilter("P");
                                }
                              }}
                            >
                              Pending
                              {selectedStatus === "P" && (
                                <span className="tick-mark"></span>
                              )}
                            </a>
                          </li>
                          <li>
                            <a
                              className={`dropdown-item${
                                selectedStatus === "I" ? " active" : ""
                              }`}
                              role="option"
                              data-index="3"
                              tabIndex={0}
                              aria-label="In Progress 3 out of 7"
                              aria-selected={selectedStatus === "I"}
                              onClick={() => handleStatusFilter("I")}
                              onKeyDown={(e) => {
                                if (e.key === "Enter") {
                                  handleStatusFilter("I");
                                }
                              }}
                            >
                              
                              In Progress
                              {selectedStatus === "I" && (
                                <span className="tick-mark"></span>
                              )}
                            </a>
                          </li>
                          <li>
                            <a
                              className={`dropdown-item${
                                selectedStatus === "D" ? " active" : ""
                              }`}
                              role="option"
                              data-index="4"
                              tabIndex={0}
                              aria-label="Draft 4 out of 7"
                              aria-selected={selectedStatus === "D"}
                              onClick={() => handleStatusFilter("D")}
                              onKeyDown={(e) => {
                                if (e.key === "Enter") {
                                  handleStatusFilter("D");
                                }
                              }}
                            >
                              Draft
                              {selectedStatus === "D" && (
                                <span className="tick-mark"></span>
                              )}
                            </a>
                          </li>
                          <li>
                            <a
                              className={`dropdown-item${
                                selectedStatus === "R" ? " active" : ""
                              }`}
                              role="option"
                              data-index="5"
                              tabIndex={0}
                              aria-label="Reviewed 5 out of 7"
                              aria-selected={selectedStatus === "R"}
                              onClick={() => handleStatusFilter("R")}
                              onKeyDown={(e) => {
                                if (e.key === "Enter") {
                                  handleStatusFilter("R");
                                }
                              }}
                            >
                              Reviewed
                              {selectedStatus === "R" && (
                                <span className="tick-mark"></span>
                              )}
                            </a>
                          </li>
                          <li>
                            <a
                              className={`dropdown-item${
                                selectedStatus === "A" ? " active" : ""
                              }`}
                              role="option"
                              data-index="6"
                              tabIndex={0}
                              aria-label="Approved 6 out of 7"
                              aria-selected={selectedStatus === "A"}
                              onClick={() => handleStatusFilter("A")}
                              onKeyDown={(e) => {
                                if (e.key === "Enter") {
                                  handleStatusFilter("A");
                                }
                              }}
                            >
                              Approved
                              {selectedStatus === "A" && (
                                <span className="tick-mark"></span>
                              )}
                            </a>
                          </li>
                          <li>
                            <a
                              className={`dropdown-item${
                                selectedStatus === "F" ? " active" : ""
                              }`}
                              role="option"
                              data-index="7" 
                              tabIndex={0}
                              aria-label="Failed 7 out of 7"
                              aria-selected={selectedStatus === "F"}
                              onClick={() => handleStatusFilter("F")}
                              onKeyDown={(e) => {
                                if (e.key === "Enter") {
                                  handleStatusFilter("F");
                                }
                              }}
                            >
                              Failed
                              {selectedStatus === "F" && (
                                <span className="tick-mark"></span>
                              )}
                            </a>
                          </li>
                        </>
                      ) : (
                        <>
                          <li>
                            <a
                              className={`dropdown-item${
                                selectedStatus === "D" ? " active" : ""
                              }`}
                              href="#"
                              onClick={() => handleStatusFilter("D")}
                            >
                              Draft
                              {selectedStatus === "D" && (
                                <span className="tick-mark"></span>
                              )}
                            </a>
                          </li>
                          <li>
                            <a
                              className={`dropdown-item${
                                selectedStatus === "R" ? " active" : ""
                              }`}
                              href="#"
                              onClick={() => handleStatusFilter("R")}
                            >
                              Reviewed
                              {selectedStatus === "R" && (
                                <span className="tick-mark"></span>
                              )}
                            </a>
                          </li>
                          <li>
                            <a
                              className={`dropdown-item${
                                selectedStatus === "A" ? " active" : ""
                              }`}
                              href="#"
                              onClick={() => handleStatusFilter("A")}
                            >
                              Approved
                              {selectedStatus === "A" && (
                                <span className="tick-mark"></span>
                              )}
                            </a>
                          </li>
                        </>
                      )}
                    </ul>
                  </div>
                </div>
                <div className="datePick col-md-4 col-lg-3">
                <div id="aria-live-region" aria-live="off" style={{ position: 'absolute', overflow: 'hidden', clip: 'rect(0 0 0 0)', height: '1px', width: '1px' }}> </div>
                  <DatePicker
                    showIcon
                    selected={selectedDate}
                    onCalendarOpen={() => handleDatePickerToggle(true)}
                    onCalendarClose={() => handleDatePickerToggle(false)}
                    onChange={(date: any) => {
                      setSelectedDate(date)
                      // filterImages(images, filterValue, date)
                      filterImages(images, filterValue, selectedDate, search);
                    setTimeout(() => {
                      const closeBtnDatepicker = document.querySelectorAll(".react-datepicker__close-icon");
                      closeBtnDatepicker.forEach((btn) => {
                        btn.setAttribute("tabindex", "0");
                    })
                    },1000)
                    }}
                    placeholderText="mm/dd/yyyy"
                    className="datePicker mb-2"
                    closeOnScroll={true}
                    dateFormat="MM/dd/yyyy"
                    isClearable
                    onChangeRaw={(date: any) => handleChangeRaw(date, Event)}
                    maxDate={new Date()}
                  />
                </div>
              </div>
              <div className="dataTable">
                <DataTable
                  paginationDefaultPage={currentPage}
                  onChangePage={(page, _) => setCurrentPage(page)}
                  customStyles={tableCustomStyles}
                  title={
                    <div className="custom-title" role="heading" aria-level={2}>
                      Total files ({filteredImages.length}{" "}
                      {filteredImages.length > 1 ? "files" : "file"})
                    </div>
                  }
                  columns={columns}
                  data={filteredImages}
                  pagination
                  paginationPerPage={25}
                  paginationRowsPerPageOptions={[10, 25, 50, 100]}
                  responsive={true}
                  // fixedHeader
                  // fixedHeaderScrollHeight='250px'
                  selectableRows
                  selectableRowDisabled={rowDisabledCriteria}
                  noContextMenu
                  onRowClicked={handleRowClicked}
                  // contextActions={contextActions}
                  // selectableRowDisabled={(row: any) => row.status === 'I'}
                  onSelectedRowsChange={handleRowSelected}
                  clearSelectedRows={toggleCleared}
                  selectableRowsHighlight
                  highlightOnHover
                  noDataComponent={
                    isZipData && filteredImages.length === 0 ? (
                      <div className="noDataComponent">No images found</div>
                    ) : (
                      !isZipData && imagesOnFilter && (
                        <div className="noDataComponent">
                          No images found
                        </div>
                      )
                      )
                  }
                  actions={
                    filteredImages.length > 0 && (
                      <>
                      <div>
                        <button
                          className="btn btn-sm btn-info refresh-btn"
                          onClick={() => handleRefresh()}
                        >
                          <i className="refreshIcon"></i>
                          Refresh
                        </button>
                        {isAdminLogin && (
                          <button
                            className="btn btn-sm btn-info delete-btn"
                            onClick={handleDelete}
                          >
                            <i className="trashIcon"></i>Delete
                          </button>
                        )}
                        <button className="btn btn-sm btn-info export-btn"
                         onClick={exportSelectedData} disabled={exporting}
                         style={{ opacity: exporting ? 0.5 : 1 }} >
                          <i className="exportIcon"></i>Export
                         </button>
                        {/* <button className="btn btn-sm btn-info import-btn"><i className="importIcon"></i>Import</button> */}
                        {isAdminLogin && (
                          <button
                            className="btn btn-sm btn-info assign-btn"
                            onClick={handleAssign}
                          >
                            <i className="assignIcon"></i>Assign
                          </button>
                        )} 
                        </div> 
                      </>     
                    )
                  }
                />
              </div>

              {showModal && (
                <ConfirmationModal
                  action={modalAction}
                  text={modalText}
                  header={modalHeader}
                  showInput={showInput}
                  onConfirm={handleModalConfirm}
                  onCancel={handleModalCancel}
                  confirmButtonDisabled={deleting}
                  userDetails={userDetails}
                />
              )}
            </>
          )}
        </>
      )}
    </div>
  )
}

export default ImageTable
