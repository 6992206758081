import React, { useState, useEffect } from "react"
import { Carousel } from "react-bootstrap";
// import carouselData from "./CarouselData"
import Image1 from "../../assets/images/CarouselImages/1.svg";
import Image2 from "../../assets/images/CarouselImages/2.svg";
import Image3 from "../../assets/images/CarouselImages/3.svg";
import Image4 from "../../assets/images/CarouselImages/4.svg";
import Image5 from "../../assets/images/CarouselImages/5.svg";
import Image6 from "../../assets/images/CarouselImages/6.svg";
import Image7 from "../../assets/images/CarouselImages/7.svg";
import Image8 from "../../assets/images/CarouselImages/8.svg";
import Image9 from "../../assets/images/CarouselImages/9.svg";
import Image10 from "../../assets/images/CarouselImages/10.svg";

const LeftContainer = () => {
  const [index, setIndex] = useState(0);
  const [images, setImages] = useState<any>([]);

  // carouselData.js

const carouselData = [
    {
      imageUrl: Image1,
      altText: "A painting of a historical village with thatched-roof structures, people engaged in activities like farming and parades, and a lush green landscape with cultivated fields and gardens."
    },
    {
      imageUrl: Image2,
      altText: "A group of people hunting a mammoth in shallow water. The group wields spears as the mammoth defends itself, with birds in the background capturing the intensity of the hunt."
    },
    {
      imageUrl: Image3,
      altText: "A periodic table showing list of transition elements and main-group elements."
    },
    {
      imageUrl: Image4,
      altText: "A mathematical formula for determining the distance 'd' between two points. This is illustrated as the square root of adding the squares of the differences in 'x' coordinates (x₂ - x₁) and 'y' coordinates (y₂ - y₁). The squared terms are highlighted in purple and orange."
    },
    {
      imageUrl: Image5,
      altText: "A diagram showing the process of ionic bonding and covalent bonding."
    },
    {
      imageUrl: Image6,
      altText: "The graph shows a right-angled triangle, drawn starting at point A (0,0), moving horizontally to point B (10,0), and then upwards to point C (10,5) with the right angle at point B."
    },
    {
      imageUrl: Image7,
      altText: "The diagrams showing how to check the fracture area of a person's arm."
    },
    {
      imageUrl: Image8,
      altText: "A Cavendish balance setup includes a torsion fiber with large and small spheres attached at the sides, a mirror at the pivot point, a light source directed at the mirror, and a scale showing the direction of rotation. It demonstrates use of light reflection to measure movements of spheres."
    },
    {
      imageUrl: Image9,
      altText: "A black and white image captures a dramatic volcanic eruption. Thick, billowing smoke and ash spew vigorously from the mountain's peak, filling the sky. The volcano's slopes are streaked with snow or ash, contrasting with the dark plumes above. Clouds shroud the scene partially, adding to the ominous atmosphere."
    },
    {
      imageUrl: Image10,
      altText: "A person dressed in traditional Maasai attire, notably a shuka, walks across the savannah. They are facing away from the camera, toward a herd of zebras grazing in the distance under a clear, airy sky. The setting suggests an East African landscape."
    }
  ];
  useEffect(() => {
    // Preload images
    const preloadImages = carouselData.map(item => {
      const image = new Image();
      image.src = item.imageUrl;
      return image;
    });
    setImages(preloadImages);
  }, []);

  const handleSelect = (selectedIndex: any) => {
    setIndex(selectedIndex);
  };
  return (
    <div className="col-sm-12 col-xs-12 col-md-12 col-lg-5 left-container">
      <div className="left-container__img">
        <div className="col-sm-12 col-xs-12 col-md-10 left-container__header-text">
          <div className="row">
            <div className="col-sm-12 col-xs-12 col-md-12 left-container__logoWhite">
              <img width={200}
                src={require("../../assets/images/logo-equalsense-beta.png")}
                alt="Equalsense brand Logo in sign in page on left side"
              />
            </div>
          </div>
        </div>
        <div className="row left-container__parentRow">
          <div className="col-sm-12 col-xs-12 col-md-10 ver-center-xl left-container__slider-image">
            {/* Render Carousel */}
            {images.length > 0 && (
              <Carousel slide={false} indicators={false} className="carousel-container">
                {carouselData.map((item, index) => (
                  <Carousel.Item key={index}>
                    <div className="row">
                      <div className="col-sm-12 col-xs-12 col-md-12 left-container__imgLogin">
                        <img
                          src={item.imageUrl}
                          alt={item.altText}
                        />
                      </div>
                    </div>
                    <div className="row justifyCenter">
                      <div className="col-sm-12 col-xs-12 col-md-10 left-container__imgAlt">
                            <span className="altColor">Alt :</span>{" "}
                            <span style={{color: 'black'}}>
                              {item.altText}
                            </span>
                      </div>
                    </div>
                  </Carousel.Item>
                ))}
              </Carousel>
            )}
          </div>
          <div className="row justifyCenter">
            <div className="col-sm-12 col-xs-12 col-md-10 left-container__title1">
              Effective, Easy and Fast
            </div>
          </div>
          <div className="row justifyCenter">
            <div className="col-sm-12 col-xs-12 col-md-10 left-container__desc1">
            Enrich your images with automated Alt-Text, enhances accessibility for everyone.
            </div>
          </div>
          <div className="row"></div>
          <div className="row"></div>
        </div>
      </div>
    </div>
  )
}

export default LeftContainer
