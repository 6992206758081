import React, { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import "./ChangePassword.scss"
import { toast, ToastContainer, cssTransition } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import axios from "axios"
// let errorMessage = ""
const ChangePassword = () => {
  const navigate = useNavigate()
  const [currentPassword, setCurrentPassword] = useState("")
  const [changePasswordBtnState, setChangePasswordBtnState] = useState("")
  const [newPassword, setNewPassword] = useState("")
  const [confirmPassword, setConfirmPassword] = useState("")
  const apiBasePath = process.env.REACT_APP_API_URL
  const basePath = process.env.REACT_APP_BASE_PATH
  const [currentPasswordType, setCurrentPasswordType] =
    useState<string>("password")
  const [newPasswordType, setNewPasswordType] = useState<string>("password")
  const [confirmPasswordType, setConfirmPasswordType] =
    useState<string>("password")
  const customId = "error-toast-id"
  const errorNotificaion = (message: string) => {
    toast.error(message, {
      position: toast.POSITION.TOP_CENTER,
      // eslint-disable-next-line
      icon: false,
      toastId: customId,
      containerId: "B"
    })
  }

  useEffect(() => {
    const handleEscPress = (event: { keyCode: number }) => {
      if (event.keyCode === 27) {
        // Close the toast when the Esc key (key code 27) is pressed
        toast.dismiss(customId) // Dismiss all toasts in the container
      }
    }

    // Add the event listener when the component mounts
    document.addEventListener("keydown", handleEscPress)

    // Clean up the event listener when the component unmounts
    return () => {
      document.removeEventListener("keydown", handleEscPress)
    }
  }, [])

  const assignId = "toast-id-assign"
  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault()
    let errorMessage = ""
    toast.dismiss(customId)

    // console.log(currentPassword === " ");
    const hasSpaces = (str: string) => /\s/.test(str)
    if (
      hasSpaces(currentPassword) ||
      hasSpaces(confirmPassword) ||
      hasSpaces(newPassword)
    ) {
      errorMessage = "Spaces are not allowed."
    } else if (
      currentPassword.length === 0 ||
      confirmPassword.length === 0 ||
      newPassword.length === 0
    ) {
      errorMessage = "All fields are required."
    } else if (newPassword.length < 8 || confirmPassword.length < 8) {
      errorMessage = "Password must have minimum 8 characters."
    } else if (!/\d/.test(newPassword) || !/\d/.test(confirmPassword)) {
      errorMessage = "Password must have at least 1 numerical value."
    } else if (!/[A-Z]/.test(newPassword) || !/[A-Z]/.test(confirmPassword)) {
      errorMessage = "Password must have at least 1 uppercase alphabet."
    } else if (!/[a-z]/.test(newPassword) || !/[a-z]/.test(confirmPassword)) {
      errorMessage = "Password must have at least 1 lowercase alphabet."
    } else if (
      !/[^a-zA-Z0-9]/.test(newPassword) ||
      !/[^a-zA-Z0-9]/.test(confirmPassword)
    ) {
      errorMessage = "Password must have at least 1 special character."
    } else if (newPassword === currentPassword) {
      errorMessage = "Current and New password should NOT be same."
    } else if (newPassword !== confirmPassword) {
      errorMessage = "New and confirm passwords should be same."
    } else {
      errorMessage = ""
      // Api call

      const userToken = localStorage.getItem("user-token")
      // console.log(userToken, "userToken")

      if (userToken !== null) {
        axios
          .post(
            `${apiBasePath}api/changePassword`,
            {
              current_password: currentPassword,
              new_password: newPassword,
              confirm_password: confirmPassword
            },
            {
              headers: {
                Authorization: `Bearer ${userToken}`
              }
            }
          )
          .then((response) => {
            if (response.status === 200) {
              // localStorage.clear()
              // navigate(`${basePath}`)
              const Enter = cssTransition({
                enter: "fade-in",
                exit: "fade-out",
                appendPosition: false,
                collapse: true,
                collapseDuration: 300
              })
              toast.success("Your password has been changed successfully.", {
                position: toast.POSITION.TOP_CENTER,
                // eslint-disable-next-line
                icon: false,
                toastId: assignId,
                transition: Enter,
                containerId: "Success"
              })

              // setChangePasswordBtnState('disabled')
            }
            // console.log(response);
          })
          .catch((error) => {
            toast.dismiss(assignId)
            setChangePasswordBtnState("")
            errorNotificaion(error.response.data.message)
          })
      }
    }
    if (errorMessage) {
      errorNotificaion(errorMessage)
    }
  }
  const handleInputChange = () => {
    toast.dismiss(customId)
    toast.dismiss(assignId)
  }
  const togglePassword = (field: string) => {
    if (field === "current") {
      setCurrentPasswordType((prevType) =>
        prevType === "password" ? "text" : "password"
      )
    } else if (field === "new") {
      setNewPasswordType((prevType) =>
        prevType === "password" ? "text" : "password"
      )
    } else if (field === "confirm") {
      setConfirmPasswordType((prevType) =>
        prevType === "password" ? "text" : "password"
      )
    }
  }
  const CloseButton = ({ closeToast }: any) => (
    <div className="Login-error-close-btn" onClick={closeToast}></div>
  )
  return (
    <div id="mainContent" className="container reset-password">
      <div className="breadcrumb">
        <div className="imageDetails container mt-4">
          <a
            type="button"
            className="btn btn-primary dashboardPath mb-1 me-1"
            href={`${basePath}/Dashboard`}
          >
            <span className="dashboardLinkText">Dashboard </span>
          </a>
          <span>/</span>
          <span className="slashPath ms-1">Change Password</span>
        </div>
      </div>
      <div className="row justify-content-center reset-container-box">
        <div className="col-lg-6 col-md-8 mx-auto reset-pwd-wrapper">
          <div className="reset-password__textContainer mx-auto text-center mb-3">
            <div className="col-12 mx-auto text-center mb-3">
              <ToastContainer
                enableMultiContainer
                containerId={"B"}
                className="reset-notification-container"
                toastClassName="reset-notification-wrapper"
                bodyClassName="reset-notification-body"
                closeButton={CloseButton}
                hideProgressBar={true}
                autoClose={false}
              />
            </div>
            <div className="change-password-text mb-2">Change Password</div>
            <div className="sub-text">
              Embrace the Power of Words with our Alt Text
            </div>
          </div>
          <form onSubmit={handleSubmit}>
            <div className="form-group mb-3 form-field">
              <input
                type={currentPasswordType}
                className="form-control input-field"
                id="currentPassword"
                placeholder="Enter current password"
                value={currentPassword}
                maxLength={32}
                onChange={(e) => {
                  setCurrentPassword(e.target.value)
                  handleInputChange()
                }}
              />
              <div className="togglePassword">
                <button
                  type="button"
                  className={`eye-btn ${
                    currentPasswordType === "password" ? "hidden" : "visible"
                  }`}
                  onClick={() => togglePassword("current")}
                  data-testid="toggle-current-password"
                ></button>
              </div>
            </div>
            <div className="form-group mb-3 form-field">
              <input
                type={newPasswordType}
                className="form-control input-field"
                id="newPassword"
                placeholder="Enter new password"
                value={newPassword}
                maxLength={32}
                onChange={(e) => {
                  setNewPassword(e.target.value)
                  handleInputChange()
                }}
              />
              <div className="togglePassword">
                <button
                  type="button"
                  className={`eye-btn ${
                    newPasswordType === "password" ? "hidden" : "visible"
                  }`}
                  onClick={() => togglePassword("new")}
                  data-testid="toggle-new-password"
                ></button>
              </div>
            </div>
            <div className="form-group mb-3 form-field">
              <input
                type={confirmPasswordType}
                className="form-control input-field"
                id="confirmPassword"
                value={confirmPassword}
                placeholder="Re-enter new password"
                maxLength={32}
                onChange={(e) => {
                  setConfirmPassword(e.target.value), handleInputChange()
                }}
              />
              <div className="togglePassword">
                <button
                  type="button"
                  className={`eye-btn ${
                    confirmPasswordType === "password" ? "hidden" : "visible"
                  }`}
                  onClick={() => togglePassword("confirm")}
                  data-testid="toggle-confirm-password"
                ></button>
              </div>
            </div>
            <button
              type="submit"
              className={`btn btn-primary change-password-btn ${changePasswordBtnState}`}
            >
              Change Password
            </button>
          </form>
        </div>
      </div>
    </div>
  )
}

export default ChangePassword
