import React from 'react'
import "./Footer.scss"

const Footer = () => {
  return (
      <footer className="d-flex container flex-wrap justify-content-between align-items-center py-4 border-top mx-auto">
            <div className="col-md-6 d-flex align-items-center copyrightLogo">
              <img width={125} src={require('../../assets/images/logo-footer--equalsensebeta.png')} alt="Equalsense logo" className="equalsenseFooterLogo" /> 
              |
              <i className="footerHurixLogo"></i>
            </div>
            <div className='col-md-6 d-flex justify-content-end copyright-text'>
              Copyright&copy;2024 equalsense.ai. All rights reserved.
            </div>
        </footer>
  )
}
export default Footer
