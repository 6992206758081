class IndexedDBService {
  readonly dbName
  readonly objectStoreName
  readonly dbVersion
  db: IDBDatabase | null

  constructor() {
    this.dbName = "myDB"
    this.objectStoreName = "pdfs"
    this.dbVersion = 1
    this.db = null // Database instance

    // Initialize the database when the class is instantiated
    this.initDB()
  }

  // Initialize the IndexedDB
  initDB() {
    // console.log("DEBUG: initDB called")
    return new Promise((resolve, reject) => {
      const request = indexedDB.open(this.dbName, this.dbVersion)

      request.onupgradeneeded = () => {
        // console.log("DEBUG: on upgradeneeded called")
        const db = request.result

        if (!db.objectStoreNames.contains(this.objectStoreName)) {
          // console.log("Creating object store")
          db.createObjectStore(this.objectStoreName, { keyPath: "url" })
        }
      }

      request.onsuccess = () => {
        this.db = request.result
        // console.log("DEBUG: IndexedDB initialized")
        resolve(true)
      }

      request.onerror = () => {
        // console.error("DEBUG: Error initializing IndexedDB")
        resolve(false)
      }
    })
  }

  // Add data to the IndexedDB
  addData(data: any, key: string) {
    return new Promise((resolve, reject) => {
      if (!this.db) {
        console.error("IndexedDB not initialized.")
        reject("IndexedDB not initialized")
        return
      }

      const tx = this.db.transaction(this.objectStoreName, "readwrite")
      const store = tx.objectStore(this.objectStoreName)
      store.put({ url: key, blob: data })

      tx.oncomplete = () => {
        // console.log("Data added to IndexedDB")
        resolve(data)
      }

      tx.onerror = () => {
        const error = tx.error?.message
        if (error) {
          resolve(error)
        } else {
          resolve("Unknown error")
        }
      }
    })
  }

  // Get data from the IndexedDB
  getPDFFromDB(pdfUrl: string) {
    return new Promise((resolve, reject) => {
      if (!this.db) {
        console.error("IndexedDB not initialized.")
        reject("IndexedDB not initialized")
        return
      }

      const tx = this.db.transaction(this.objectStoreName, "readonly")
      const store = tx.objectStore(this.objectStoreName)

      const getRequest = store.get(pdfUrl)

      getRequest.onsuccess = () => {
        const data = getRequest.result
        if (data) {
          resolve(data.blob)
        } else {
          resolve(null)
        }
      }

      getRequest.onerror = () => {
        resolve(null)
      }
    })
  }
}

// Singleton pattern
const indexedDBService = new IndexedDBService()

export default indexedDBService
