import React, { useState, useEffect } from "react"
import { Link, useParams, useNavigate, useLocation } from "react-router-dom"
import axios from "axios"
import Details from "../ImageTable/data.json"
import { toast, ToastContainer, cssTransition } from "react-toastify"
import { Tooltip, OverlayTrigger } from "react-bootstrap"
import PDFViewer from "./pdf"
import EPUBViewer from "./epub"

// let isMathImageMessageDisplayed = false;
const AltTextDetails = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [altTextData, setAltTextData] = useState({
    file_name: "",
    file_path: "",
    original_file_path: "",
    parentFilePath: "",
    image_status: "",
    assigned_user: "",
    user: "",
    final_alt_text: "",
    created_at: "",
    image_id: "",
    parent_id: "",
    alt_text: [
      {
        default_value: "",
        id: "",
        modal: "",
        modal_name: ""
      },
      {
        default_value: "",
        id: "",
        modal: "",
        modal_name: ""
      }
    ],
    finalLanguage: ""
  })
  const [zipParentFileName, setZipParentFileName] = useState({
    parent_file_name: ""
  })
  const [parentFileName, setParentFileName] = useState({
    parent_file_name: ""
  })

  const [detailsData, setDetailsData] = useState({
    alt_text: "",
    alt_text_2: "",
    alt_text_3: "",
    alt_text_4: "",
    file_name: "",
    file_path: "",
    image_id: "",
    status: "",
    user_id: ""
  })
  const {
    alt_text,
    alt_text_2,
    alt_text_3,
    alt_text_4,
    file_name,
    file_path,
    image_id,
    status,
    user_id
  } = detailsData
  const [filterLanguage, setFilterLanguage] = useState("English")
  const [apiData, setApiData] = useState(false)
  const [altText, setAltText] = useState<any>("")
  const [selectedOption, setSelectedOption] = useState<number | null>(null)
  const [saving, setSaving] = useState(false)
  const [saved, setSaved] = useState(false)
  const [prevData, setPrevData] = useState("")
  const [nextData, setNextData] = useState("")
  const [zipDataPath, setZipDataPath] = useState(false)
  const [isTextAreaChanged, setIsTextAreaChanged] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [showPreview, setShowPreview] = useState(false)
  const [contextData, setContextData] = useState<any>("")
  const [showUpdatingAltText, setShowUpdatingAltText] = useState(false)
  const [isSourcePdfOpen, setIsSourcePdfOpen] = useState(false)
  const [activeTab, setActiveTab] = useState("context") // Default active tab is 'context'
  const [isContextChanged, setIsContextChanged] = useState(false) // Default isContextChanged is false
  const [storeMetaData, setStoreMetaData] = useState<any>("")

  const [altTextCount, setAltTextCount] = useState<any>()

  const [pdfPageNumber, setPdfPageNumber] = useState<any>(1)
  const [displayAltPage, setDisplayAltPage] = useState(false)

  const [limitReached, setLimitReached] = useState({
    errorDetails:
      "We apologize for any inconvenience. The daily user limit for GPT-4 Turbo has been reached. Please try again tomorrow and regenerate."
  })
  const [errorGPT4, setErrorGPT4] = useState({
    errorDetails:
      "I'm sorry, I cannot assist with that request."
  })
  const [projectLanguageList, setProjectLanguageList] = useState<any[]>([])
  const [selectedProjectLanguage, setSelectedProjectLanguage] = useState<any>({
    'languageName': '',
    'languageId': ''
  })
  const [upFeedback, setUpFeedback] = useState(false) // 'thumbsUp' feedback
  const [downFeedback, setDownFeedback] = useState(false) // 'thumbsDown' feedback
  const [mathModel, setMathModel] = useState(false) // 'Math Model' feedback
  const [enableContext, setEnableContext] = useState(false) // 'Context' feedback

  const [expanded, setExpanded] = useState(false) // Read More feature
  const [storedImageStatus, setStoredImageStatus] = useState("")
  const [characterCount, setCharacterCount] = useState<any>(0)
  const [altTextIdforNoneOfAbove, setAltTextIdforNoneOfAbove] = useState<boolean>(false)
  const [isNoneOfAboveActive, setIsNoneOfAboveActive] = useState<boolean>(false)
  const [imageType, setImageType] = useState<any>("")
  const [initialSave, setInitialSave] = useState<any>(true)
  const [isMathImageMessageDisplayed, setIsMathImageMessageDisplayed] = useState<boolean>(false)
  const [enableRegenerate, setEnableRegenerate] = useState<boolean>(false)
  
  const handleTabClick = (tab: any) => {
    setActiveTab(tab)
  }

  const apiBasePath = process.env.REACT_APP_API_URL
  const basePath = process.env.REACT_APP_BASE_PATH
  const saveId = "toast-id-save"
  let detailsText: any = ""
  let modalContent: any = ""
  const { id } = useParams()
  // asset_id
  console.log(id, "asset_id")
  let textInterval:any
  let intervalId: any

  const Enter = cssTransition({
    enter: "fade-in",
    exit: "fade-out",
    appendPosition: false,
    collapse: true,
    collapseDuration: 300
  })

  const successSaveNotification = (successMessage: string) => {
    toast.success(successMessage, {
      position: toast.POSITION.TOP_CENTER,
      // eslint-disable-next-line
      icon: false,
      toastId: saveId,
      transition: Enter,
      containerId: "Success"
    })
  }

  useEffect(() => {
    // Fetch data when the component mounts or when location changes
    loadUserData(id, selectedProjectLanguage.languageId);

    // Set interval to call loadUserData every 15 seconds when image status is Pending
    intervalId = setInterval(() => {
      if (storedImageStatus === 'P' || storedImageStatus === 'I') {
        loadUserData(id, selectedProjectLanguage.languageId);
      }
    }, 15000);

    // Clear the interval when the component unmounts
    return () => {
      clearInterval(textInterval)
      clearInterval(intervalId)
    }
}, [location, id, selectedProjectLanguage.languageId, storedImageStatus]);

  const loadUserData = async (id: any, languageId: any) => {
    let isChecked = true
    const formData = new FormData()
    const userToken = localStorage.getItem("user-token")
    if (userToken !== null) formData.append("asset_id", `${id}`)
    formData.append("language_id", `${languageId}`)
    axios
      .post(`${apiBasePath}api/getSingleImageData`, formData, {
        headers: {
          Authorization: `Bearer ${userToken}`
        }
      })
      .then((response) => {
        const data = response.data.data
        const responseData = response.data
        console.log(response.data, response.data.data, response)

        if (response.status === 200) {
          if (data !== undefined) {
            // const lastIndex = data.alt_text.length - 1
            setAltTextData(data)
            setStoredImageStatus(data.image_status)
            setApiData(true)
            setUpFeedback(false)
            setDownFeedback(false)
            setZipParentFileName(responseData)
            setProjectLanguageList(responseData.language_list)
            if (data.is_none_above_active === 0) {
              setIsNoneOfAboveActive(false)
            } else {
              setIsNoneOfAboveActive(true)
            }
            if (data.meta_data !== null) {
              setStoreMetaData(data.meta_data)
              setContextData(data.context)
            } else {
              setContextData(data.context)
            }
            if (data.parentFilePath.includes(".pdf") || data.parentFilePath.includes(".epub")) {
              const pdfNumber = Number(
                data.file_name.split("-")[1].split("_")[0]
              )
              handleSourcePdfPageChange(pdfNumber)
            } else {
              setDisplayAltPage(true)
            }
            setNextData(response.data.next_id)
            setPrevData(response.data.prev_id)
            if (data.image_status !== "P" && data.alt_text !== null) {
              const altTextWithValueOne = data.alt_text.find(
                (altText: any) => altText.default_value === 1
              )
              if (altTextWithValueOne !== undefined) {
                const selectedOptionIndex = data.alt_text.findIndex(
                  (altText: any) => altText.id === altTextWithValueOne.id
                )
                setSelectedOption(selectedOptionIndex)
                if (data.image_status === "R" || data.image_status === "A") {
                  setAltText(data.final_alt_text)
                  setCharacterCount(data.final_alt_text.length)
                } else {
                  setAltText(altTextWithValueOne.modal)
                  setCharacterCount(altTextWithValueOne.modal.length)
                }
              } else {
                setAltText(data.final_alt_text)
                setCharacterCount(data.final_alt_text.length)
              }
            } else if (data.image_status === "I") {
              setShowUpdatingAltText(true)
              setAltText("")
              setCharacterCount(0)
            }
            if (data.image_status !== "P" && data.context !== null) {
              data.alt_text.map((altText: any) => {
                if (altText.modal === "None of the above" && altText.default_value === 1) {
                  setAltText(altText.modal)
                  setCharacterCount("NA")
                }
                if (altText.modal === "is_math_image") {
                  setIsMathImageMessageDisplayed(true)
                  setAltText('')
                  setCharacterCount("NA")
                  setEnableRegenerate(true)
                } else {
                  setIsMathImageMessageDisplayed(false)
                  setEnableRegenerate(false)
                }
              })
            }
            if (data.asset_modal_type === "M") {
              setMathModel(true)
              setEnableContext(true)
              setAltTextCount(1)
              setImageType("math")
              // setActiveTab("metadata")
            } else {
              if (`${basePath}` !== "") { // dev
                if (data.context !== null && data.context !== "") {
                  setAltTextCount(4) // for image with context
                } else {
                  setAltTextCount(4) // for image without context
                }
              } else { // production
                if (data.context !== null && data.context !== "") {
                  setAltTextCount(3) // for image with context
                } else {
                  setAltTextCount(2) // for image without context
                }
              }
              setMathModel(false)
              setEnableContext(false)
              setImageType("non-math")
            }
            if (data.image_status === "R" && data.final_alt_text !== null) {
              // Set final_alt_text as altText if image status is "Reviewed"
              setAltText(data.final_alt_text)
            }
            if (data.image_status === "A") {
              // Set final_alt_text as altText if image status is "Approved"
              setAltText(data.final_alt_text)
            }
            if (data.image_status === "P" || data.image_status === "I") {
              setShowUpdatingAltText(true)
              setAltText("")
              setCharacterCount(0)
            }
            if (data.parent_id !== "0") {
              setZipDataPath(true)
              setParentFileName(responseData)
            }
            console.log(data, ",", data[0])
            if (data.alt_text !== null) {
              // if (data.alt_text[1].modal !== '' && data.alt_text[2].modal !== '') {
                if (data.image_status === "D" || data.image_status === "R") {
                // If data.text is blank, stop the recursion
                clearInterval(textInterval)
                clearInterval(intervalId)
                return;
              }
            }
          } else {
            // setFetchAPI(false)
            navigate(`${basePath}/Dashboard`)
          }
        }
      })
      .catch((error) => {
        console.log(error)
        // setApiError(true)
      })
  }

  // useEffect(() => {
  //   loadUserData(id, selectedProjectLanguage.languageId)
  //   console.log(detailsData, altTextData)
  // }, [])

  // useEffect(() => {
  //   // Fetch the PDF file in the background
  //   const fetchPDFFile = async () => {
  //     try {
  //       const response = await fetch(altTextData.parentFilePath)
  //       if (response.ok) {
  //         console.log(
  //           "PDF file fetched successfully:",
  //           altTextData.parentFilePath
  //         )
  //         // Optionally, you can store the PDF content in state or perform other actions here.
  //       } else {
  //         console.error(
  //           "Failed to fetch PDF file:",
  //           response.status,
  //           response.statusText
  //         )
  //       }
  //     } catch (error) {
  //       console.error("Error fetching PDF file:", error)
  //     }
  //   }

  //   fetchPDFFile()
  // }, [altTextData.parentFilePath])

  console.log(detailsData, "data", detailsData)

  const filterLanguageValue = (imagesData: any, filter: any) => {
    // if (filter === 'English') {
    //   setFilterLanguage(imagesData)
    // } else {
    //   const filtered = imagesData.filter((image: { status: any }) => image.status === filter)
    //   setFilterLanguage(filtered)
    // }
  }
  const handleRadioClick = (index: number) => {
    // Reset the saved state
    if (altTextData.alt_text[index] !== undefined) {
      if (altTextData.alt_text[index].modal_name === "LaTex Output" ||
        altTextData.alt_text[index].modal_name === "MathML Output") {
        return false
      }
    }
    //setSaved(false)
    if (altTextData.image_status === "R") {
      setSaved(true)
    }
    if (isTextAreaChanged === false) {
      setIsTextAreaChanged(true)
    }
    if (detailsText !== "") {
      detailsText = ""
    }
    toast.dismiss()
    setAltText("")
    if (mathModel) {
      if (altTextData.alt_text[index] !== undefined) {
        detailsText = altTextData.alt_text[index].modal
        setAltText(detailsText)
        setCharacterCount(detailsText.length)
      } else {
        detailsText = "No Alt-Text is Selected"
        setAltText(detailsText)
        setCharacterCount("NA")
      }
    } else if (altTextData.alt_text[index] !== undefined && altTextData.alt_text[index].modal_name === null) {
      detailsText = "No Alt-Text is Selected"
      setAltText(detailsText)
      setCharacterCount("NA")
    } else {
      detailsText = document.querySelector(`.form-control-details__${index}`)
      if (detailsText.textContent === "None of the above") {
        detailsText = "No Alt-Text is Selected"
        setCharacterCount("NA")
      } else {
        detailsText = detailsText.textContent
        setCharacterCount(detailsText.length)
      }
      setAltText(detailsText)
    }
    if (document.querySelector('.none-of-above-option')?.classList.contains(String(index))) {
      setAltTextIdforNoneOfAbove(true)
    } else {
      setAltTextIdforNoneOfAbove(false)
    }
    console.log(detailsText, "DETAILS TEXT")
    setSelectedOption(index)
  }
  const handleAltTextChange = (event: any) => {
    toast.dismiss()
    setAltText(event.target.value)
    setCharacterCount(event.target.value.length)
    // Set isTextAreaChanged to true when there's a change in the textarea
    setIsTextAreaChanged(true)
    // Reset the saved state
    //setSaved(false)
    if (altTextData.image_status === "R") {
      setSaved(true)
    } else {
      setSaved(false)
    }
  }

  const handleSave = () => {
    setSaving(true)
    setSaved(false)

    const altTextWithValueOne: any = altTextData.alt_text.find(
      (altText: any) => altText.default_value === 1
    )

    const requestData = {
      image_id: altTextData.image_id,
      alt_text_data: altText,
      ...(selectedOption !== null && {
        alt_text_id: altTextIdforNoneOfAbove ? 0 : altTextData.alt_text[selectedOption].id
      }),
      ...(selectedOption === null && { alt_text_id: altTextWithValueOne.id }),
      ...((isContextChanged || !isContextChanged) && { context: contextData }),
      status: (altTextData.image_status === "D") ? "R" : "R" ? "R" : "D",
    }

    axios
      .post(`${apiBasePath}api/setImageReviewed`, requestData, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("user-token")}`
        }
      })
      .then((response) => {
        // Handle the response if needed
        console.log(response)
        if (response.status === 200) {
          setSaving(false)
          //setSaved(true)
          successSaveNotification(response.data.message)
          // Reset the saved state after a certain duration if needed
          setIsTextAreaChanged(false)
          // if (requestData.status === "R") {
            setSaved(false)
            setInitialSave(false)
          // }
          setTimeout(() => {
            // Update the image status to "Reviewed"
            if (altTextData.image_status === "D") {
              setAltTextData((prevData) => ({
                ...prevData,
                image_status: "R"
              }))
            } 
            // else if (altTextData.image_status === "R") {
            //   setAltTextData((prevData) => ({
            //     ...prevData,
            //     image_status: "A"
            //   }))
            // }
            // setSelectedOption(null)
          }, 100) // Reset after 2 seconds (adjust the duration as per your requirement)
        }
      })
      .catch((error) => {
        // Handle the error if needed
        console.log(error)
        setSaving(false)

        if (error.response && error.response.status === 401) {
          // Show toast error message when the data is deleted (401 Unauthorized)
          toast.error(error.response.data.error, {
            position: toast.POSITION.TOP_CENTER,
            // eslint-disable-next-line
            icon: false,
            transition: Enter,
            containerId: "Error"
          })
        } else {
          // Show a generic error message for other errors
          toast.error("An error occurred. Please try again later.", {
            position: toast.POSITION.TOP_CENTER,
            // eslint-disable-next-line
            icon: false,
            transition: Enter,
            containerId: "Error"
          })
        }
      })
  }

  useEffect(() => {
    filterLanguageValue(detailsData, filterLanguage)
    console.log(altTextData, "----")
  }, [filterLanguage])

  useEffect(() => {
    if (nextData === null) {
      document.querySelector(".next-btn")?.classList.add("disableNext")
    } else {
      document.querySelector(".next-btn")?.classList.remove("disableNext")
    }
    if (prevData === null) {
      document.querySelector(".prev-btn")?.classList.add("disablePrev")
    } else {
      document.querySelector(".prev-btn")?.classList.remove("disablePrev")
    }
  })
  const navigateToPrevData = () => {
    toast.dismiss()
    setSaved(false)
    setSaving(false)
    setIsTextAreaChanged(false)
    setSelectedProjectLanguage({
      languageName: '',
      languageId: ''
    })
    // setShowUpdatingAltText(false)
    if (prevData !== null) {
      navigate(`${basePath}/altTextDetails/${prevData}`)
      loadUserData(prevData, selectedProjectLanguage.languageId='')
      // setIsNoneOfAboveActive(false)
      // setIsMathImageMessageDisplayed(false)
    }
  }
  const navigateToNextdata = () => {
    toast.dismiss()
    setSelectedProjectLanguage({
      languageName: '',
      languageId: ''
    })
    setIsTextAreaChanged(false)
    setSaved(false)
    setSaving(false)
    // setShowUpdatingAltText(false)
    if (nextData !== null) {
      navigate(`${basePath}/altTextDetails/${nextData}`)
      loadUserData(nextData, selectedProjectLanguage.languageId='')
      // setIsNoneOfAboveActive(false)
      // setIsMathImageMessageDisplayed(false)
    }
  }

  const backToDashboard = () => {
    toast.dismiss()
    if (altTextData.parent_id !== "0") {
      navigate(`../${basePath}/Dashboard/${altTextData.parent_id}`)
    } else {
      navigate(`../${basePath}/Dashboard`)
    }
  }

  // Function to handle opening and closing of the modal
  const handleModalToggle = () => {
    setShowModal(!showModal)
  }

  // Function to handle showing and hiding the preview
  const handleShowPreview = () => {
    setShowPreview(true)
  }

  const handleHidePreview = () => {
    setShowPreview(false)
  }

  const handleContextChange = (event: any) => {
    toast.dismiss()
    setContextData(event.target.value)
    // Set isContextChanged to true when there's a change in the textarea
    if (event.target.value !== "") {
      setIsContextChanged(true)
    } else {
      setIsContextChanged(false)
    }
  }

  const handleSourcePdfPageChange = (newPageNumber: number) => {
    setPdfPageNumber(newPageNumber)
  }

  const regenerateAltText = () => {
    const formData = new FormData()
    const userToken = localStorage.getItem("user-token")
    if (userToken !== null) formData.append("asset_id", `${id}`)
    formData.append("context", `${contextData}`)
  formData.append("is_math", `${imageType === "math" ? "M" : "I"}`)
    axios
      .post(`${apiBasePath}api/generateAltText`, formData, {
        headers: {
          Authorization: `Bearer ${userToken}`
        }
      })
      .then((response) => {
        console.log(response.data, response.data.data, response)
        if (response.status === 200) {
          loadUserData(id, selectedProjectLanguage.languageId)
          setIsContextChanged(false)
          setIsTextAreaChanged(false)
          setShowUpdatingAltText(true)
          setAltTextData((prevData) => ({
            ...prevData,
            image_status: response.data.statusImage
          }))
          setAltText("")
          setCharacterCount(0)
          setSaved(false)
          setSaving(false)
          setInitialSave(true)
          successSaveNotification(response.data.message)
          //Continue calling loadUserData after a delay
          textInterval = setInterval(() => {
            loadUserData(id, selectedProjectLanguage.languageId);
          }, 15000);
        }
      })
      .catch((error) => {
        const responseStatus = error.response.status
        const errMsg = error.response.data.error
            if (error.response && responseStatus === 400) {
              toast.error(errMsg, {
                position: toast.POSITION.TOP_CENTER,
                // eslint-disable-next-line
                icon: false,
                transition: Enter,
                containerId: "Error"
              })
            }
        console.log(error)
      })
  }

  // Filter out the options with modal_name as "Hurix Reference Model" if ${basePath} is an empty string
  const filteredAltText = altTextData.alt_text !== null
  ? altTextData.alt_text.filter((element: any) => {
      return !((element.modal_name === "Hurix Reference Model" || element.modal === "Limit_reached") && `${basePath}` === "");
    })
  : [];
  // const filteredAltText = altTextData.alt_text !== null
  //   ? altTextData.alt_text.filter((element: any) => {
  //       return !((element.modal_name === "Hurix Reference Model" || element.modal === "Limit_reached") && 
  //       `${basePath}` === "") && element.modal_name !== null;
  //   })
  // : [];

  const toggleProjectLanguage = (selectedLanguage: any) => {
    toast.dismiss()
    setApiData(false)
    setSelectedProjectLanguage({
      languageName: selectedLanguage.languageName,
      languageId: selectedLanguage.languageId
      })
      loadUserData(id, selectedLanguage.languageId)
  }

  const handleThumbsUp = (altText: any, index: any) => {
    const userToken = localStorage.getItem("user-token")
    const formData = new FormData()
    formData.append("id", `${altText.id}`)
    formData.append("rankingStatus", "U")
    formData.append("image_id", `${altTextData.image_id}`)
    axios
      .post(`${apiBasePath}api/altTextRanking`, formData, {
          headers: {
            Authorization: `Bearer ${userToken}`
        }
      })
      .then((response) => {
        console.log(response.data, response.data.data, response)
        if (response.status === 200) {
          if (altTextData.alt_text[index].id === altText.id) {
            setUpFeedback(true)
            setDownFeedback(false)
            loadUserData(id, selectedProjectLanguage.languageId)
          }
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }
  const handleThumbsDown = (altText: any, index: any) => {
    const userToken = localStorage.getItem("user-token")
    const formData = new FormData()
    formData.append("id", `${altText.id}`)
    formData.append("rankingStatus", "D")
    formData.append("image_id", `${altTextData.image_id}`)
    axios
      .post(`${apiBasePath}api/altTextRanking`, formData, {
          headers: {
            Authorization: `Bearer ${userToken}`
        }
      })
      .then((response) => {
        console.log(response.data, response.data.data, response)
        if (response.status === 200) {
          if (altTextData.alt_text[index].id === altText.id) {
            setDownFeedback(true)
            setUpFeedback(false)
            loadUserData(id, selectedProjectLanguage.languageId)
          }
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }

  const toggleExpand = () => {
    setExpanded(!expanded);
  }

  // console.log(projectLanguageList, "projectLanguageList")

  // console.log(altTextCount, "altTextCount")

  return (
    <div className={`imageDetails container mt-4 ${!apiData ? 'detailsContainer' : ''}`}>
      {zipDataPath ? (
        <>
          <Link
            type="button"
            className="btn btn-primary dashboardPath mb-1 me-1"
            to={`${basePath}/Dashboard`}
          >
            <span className="dashboardLinkText">Dashboard </span>
          </Link>
          <span>/</span>
          <Link
            type="button"
            className="btn btn-primary dashboardPath mb-1 me-1"
            to={`${basePath}/Dashboard/${altTextData.parent_id}`}
          >
            <span className="slashPath dashboardLinkText ms-1">
              {parentFileName.parent_file_name}
            </span>
          </Link>
          <span>/</span>
          <span className="slashPath ms-1">{altTextData.file_name}</span>
        </>
      ) : (
        <>
          <Link
            type="button"
            className="btn btn-primary dashboardPath altTextPagePath mb-1 me-1"
            to={`${basePath}/Dashboard`}
          >
            <span className="dashboardLinkText">Dashboard </span>
          </Link>
          <span>/</span>
          <span className="slashPath altTextSlashPath ms-1">{altTextData.file_name}</span>
        </>
      )}
      <div>
        <span
          tabIndex={0}
          className="btn btn-primary backButton ms-3"
          onClick={backToDashboard}
          onKeyDown={(event) => {
            if (event.key === 'Enter') {
              backToDashboard();
            }
          }}
        >
          <i className="backArrow"></i>
          <span>Generated Alt Text</span>
        </span>
        {/* Language Toggle */}
        <div className="selectLanguageFilter mt-2">
          <div className="dropdown language-filter dd-userSelection">
            <span className="language-filter-label">Language Translation:</span>
              <div className="translateOptions-container">
                {projectLanguageList.map((language: any) => (
                  <>
                    <button className={`translateOptions${(selectedProjectLanguage.languageName === language.languageName ||
                        altTextData.finalLanguage === language.languageId) ? ' active' : ''}`}
                      onClick={() => toggleProjectLanguage(language)}>
                        {language.languageName} ({language.languageCode})
                    </button>
                  </>
                ))}
              </div>
          </div>
      </div>
        <div className="alt-text-status mt-3">
          Status:
          <span
            className={`alt-text-file-status ms-3 me-4 ${
              altTextData.image_status === "I"
                ? "inprogress-btn"
                : altTextData.image_status === "P"
                ? "pending-btn"
                : altTextData.image_status === "D"
                ? "draft-btn"
                : altTextData.image_status === "R"
                ? "reviewed-btn"
                : altTextData.image_status === "A"
                ? "approved-btn"
                : ""
            }`}
          >
            {altTextData.image_status === "I"
              ? "In progress"
              : altTextData.image_status === "P"
              ? "Pending"
              : altTextData.image_status === "D"
              ? "Draft"
              : altTextData.image_status === "R"
              ? "Reviewed"
              : altTextData.image_status === "A"
              ? "Approved"
              : ""}
          </span>
        </div>
      </div>
        <div className="container altTextDetails">
          {!apiData && <div className="details-loader"></div>}
          {apiData && (
          <div className="row p-3">
            <div className="image-wrapper column col-7 p-3">
              {/* Thumbnail Image */}
              <div
                className="image-preview-wrapper">
                <OverlayTrigger
                  placement={'top'}
                  overlay={
                    <Tooltip className="preview-tooltip">
                      Preview Image
                    </Tooltip>
                  }
                >
                <span className="magnify-image" onClick={handleModalToggle}></span>
              </OverlayTrigger>
                <div className="image-container">
                  <img
                    src={altTextData.file_path}
                    alt={altTextData.file_name}
                    className="img-fluid generatedImage clickable-image-thumbnail"
                  />
                  {showPreview && (
                    <div className="image-preview">
                      <div className="img-fluid preview-image"></div>
                      <span className="preview-text">
                        <i className="full-screen-icon"></i>Preview
                      </span>
                      {/* <img src={altTextData.file_path} alt={altTextData.file_name} className="img-fluid preview-image" /> */}
                    </div>
                  )}
                </div>
              </div>
              <div className="navigation mt-3">
                <button
                  className="btn btn-sm btn-info prev-btn" 
                  onClick={navigateToPrevData} 
                  tabIndex={prevData === null ? -1 : 0}
                >
                  <i className="prevIcon"></i>Prev
                </button>
                <button
                  className="btn btn-sm btn-info next-btn"
                  onClick={navigateToNextdata}
                  tabIndex={nextData === null ? -1 : 0}
                >
                  <i className="nextIcon"></i>Next
                </button>
              </div>
              {altTextData.image_status !== ("P" || "I") && (
              <div className="image-type mt-2">
                <label className="math-image-type">
                  <input
                    type="radio"
                    name="imageType"
                    value="math"
                    checked={imageType === "math"}
                    onChange={(e) => {
                      setImageType(e.target.value),
                      // setMathModel(true)
                      setEnableContext(true)
                    }}
                  />
                  Math
                </label>
                <label className="non-math-image-type">
                  <input
                    type="radio"
                    name="imageType"
                    value="non-math"
                    checked={imageType === "non-math"}
                    onChange={(e) => {
                      setImageType(e.target.value),
                      // setMathModel(false)
                      setEnableContext(false),
                      setEnableRegenerate(false)
                    }}
                  />
                  Non-Math
                </label>
              </div>
              )}
              <div className="tab-component">
                <div className="tab-header">
                  <button
                    className={`tab-button ${
                      activeTab === "context" ? "active" : ""
                    }`}
                    onClick={() => handleTabClick("context")}
                  >
                    Context
                  </button>
                  <button
                    className={`tab-button ${
                      activeTab === "metadata" ? "active" : ""
                    }`}
                    onClick={() => handleTabClick("metadata")}
                  >
                    Metadata
                  </button>
                </div>
                <div className="tab-content">
                  {activeTab === "context" && (
                    <div className="content-data mt-3 me-4">
                      {/* Content for the 'Context' tab */}
                      <textarea
                        className={`form-control ${enableContext ? "math-model disabled" : "" }`}
                        id="exampleFormControlTextarea1"
                        value={enableContext ? "" : contextData}
                        onChange={handleContextChange}
                        placeholder={(altTextData.parentFilePath.endsWith(".pdf") ? "Type/Select Context from Source PDF" :
                          altTextData.parentFilePath.endsWith(".epub") ? "Type/Select Context from Source EPUB" : "Type context here")}
                        // placeholder="Type context here"
                        disabled={enableContext}
                      ></textarea>
                      <div className="regenerate-block">
                        {altTextData.image_status === "I" ||
                        altTextData.image_status === "P" ? (
                          <button
                            className={`regenerate-btn me-2 disabled`}
                            disabled
                            tabIndex={contextData === null || contextData === "" ? -1 : 0}
                          >
                            Regenerate
                          </button>
                        ) : enableContext ? (
                          <button
                            className={`regenerate-btn me-2 ${enableRegenerate ? 'disabled' : ''}`}
                            onClick={regenerateAltText}
                            tabIndex={contextData === null || contextData === "" ? -1 : 0}
                            disabled={enableRegenerate}
                          >
                            Regenerate
                          </button>
                        ) : (
                          <button
                            className={`regenerate-btn me-2 ${enableRegenerate ? 'disabled' : ''}`}
                            onClick={regenerateAltText}
                            tabIndex={contextData === null || contextData === "" ? -1 : 0}
                            disabled={enableRegenerate}
                          >
                            Regenerate
                          </button>
                        )}
                      </div>
                    </div>
                  )}
                  {activeTab === "metadata" && (
                    <div className="metadata-info mt-3 me-4">
                      <table className="metadata-table">
                        {storeMetaData !== null && (
                          <tbody>
                            {Object.entries(storeMetaData).map(
                              ([key, value]: any) =>
                                // Skip rendering Keywords, Extra Metadata and Dimensions
                                key !== "Keywords" &&
                                key !== "Description" &&
                                key !== "Location" &&
                                key !== "Copyright" && (
                                  <tr key={key}>
                                    <td className="keyPara">{key}</td>
                                    <td className="valuePara">
                                      {typeof value === "object"
                                        ? JSON.stringify(value)
                                        : value}
                                    </td>
                                  </tr>
                                )
                            )}
                            {storeMetaData.Keywords && (
                              <tr>
                                <td className="keyPara">Keywords</td>
                                <td className="valuePara">
                                  {storeMetaData.Keywords.join(", ")}
                                </td>
                              </tr>
                            )}
                            {Object.entries(storeMetaData).map(
                              ([key, value]: any) =>
                                // Skip rendering File Name, Keywords, Extra Metadata and Dimensions
                                key !== "File Name" &&
                                key !== "Keywords" &&
                                key !== "Extra Metadata" &&
                                key !== "Dimensions" && (
                                  <tr key={key}>
                                    <td className="keyPara">{key}</td>
                                    <td className="valuePara">
                                      {typeof value === "object"
                                        ? JSON.stringify(value)
                                        : value}
                                    </td>
                                  </tr>
                                )
                            )}
                            {storeMetaData.Dimensions && (
                              <tr>
                                <td className="keyPara">Dimensions</td>
                                <td className="valuePara">
                                  {storeMetaData.Dimensions.join(" x ")}
                                </td>
                              </tr>
                            )}
                            {storeMetaData["Extra Metadata"] &&
                              Object.entries(storeMetaData["Extra Metadata"]).map(
                                ([metadataKey, metadataValue]: any) => (
                                  <tr key={metadataKey}>
                                    <td className="keyPara">{metadataKey}</td>
                                    <td className="valuePara">
                                      {typeof metadataValue === "object"
                                        ? JSON.stringify(metadataValue)
                                        : metadataValue}
                                    </td>
                                  </tr>
                                )
                              )}
                          </tbody>
                        )}
                      </table>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="alt-text-warapper column col-5">
              {(altTextData.parentFilePath.endsWith(".pdf") || altTextData.parentFilePath.endsWith(".epub")) ? (
                <div className="accordion" id="accordionExample">
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingTwo">
                      <button
                        className="accordion-button source-pdf-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseTwo"
                        aria-expanded="true"
                        aria-controls="collapseOne"
                        onClick={() => setIsSourcePdfOpen(!isSourcePdfOpen)}
                      >
                        {
                        altTextData.parentFilePath.endsWith(".pdf") ? <div className="alt-text-title">Source PDF</div> : <div className="alt-text-title">Source EPUB</div>
                      }
                      </button>
                    </h2>
                    <div
                      id="collapseTwo"
                      className="accordion-collapse collapse"
                      aria-labelledby="headingTwo"
                      data-bs-parent="#accordionExample"
                    >
                      <div className="accordion-body pdf-body">
                        {isSourcePdfOpen && (
                          altTextData.parentFilePath.endsWith(".pdf") ? 
                          <PDFViewer
                            parentFilePath={altTextData.parentFilePath}
                            pdfPageNumber={pdfPageNumber}
                            handlePageChange={handleSourcePdfPageChange}
                          /> : 
                          <EPUBViewer 
                            parentFilePath={altTextData.parentFilePath}
                            pdfPageNumber={pdfPageNumber}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item mb-5">
                    <h2 className="accordion-header" id="headingOne">
                      <button
                        className="accordion-button alt-text-header-button"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseOne"
                        aria-expanded="true"
                        aria-controls="collapseOne"
                        onClick={() => {
                          if (isSourcePdfOpen) {
                            setIsSourcePdfOpen(false)
                          }
                        }}
                      >
                        <div className="alt-text-title">Alt Text generated</div>
                      </button>
                    </h2>
                    <div
                      id="collapseOne"
                      className="accordion-collapse collapse show"
                      aria-labelledby="headingOne"
                      data-bs-parent="#accordionExample"
                    >
                      <div className="accordion-body">
                        {/* For Scholastic client_id 7 */}
                        {altTextData.alt_text !== null && localStorage.client_id === "7" ?
                          filteredAltText.map(
                            (element: any, index: number) => {
                              return (
                                <div
                                  key={index}
                                  className="altTextContainer_1 mb-3"
                                >
                                  {showUpdatingAltText &&
                                  (element?.modal_name?.includes("GPT")) &&
                                  (altTextData.image_status === "P" ||
                                    altTextData.image_status === "I") ? (
                                    <>
                                      <span className="altText_1 mt-2 ms-3">
                                        Alt Text
                                      </span>
                                      <span className="updating-alt-text mt-2 ms-3">
                                        Generating Alt Text...
                                      </span>
                                    </>
                                  ) : element.modal_name !== "" &&
                                    !element.modal.includes("Limit_reached") &&
                                    element.modal_name.includes("GPT") &&
                                    element.modal !== errorGPT4.errorDetails ? (
                                    // Append all the model options excluding the ones with "Limit_reached" in the modal
                                    <div
                                      className={`form alt-text-1 ${
                                        selectedOption === index ? "active" : ""
                                      }`}
                                      // onClick={() => handleRadioClick(index)}
                                    >
                                      {element.modal_name !== null && (
                                        <div className="engineOptions mt-2 ms-3" onClick={() => handleRadioClick(index)}>
                                          <span className="altText_1">
                                            Alt Text
                                          </span>
                                          {/* {`${basePath}` !== "" && (
                                            <div
                                              className="engineName"
                                              data-tooltip={element.modal_name}
                                            >
                                              <i className="engine-icon"></i>
                                            </div>
                                          )} */}
                                        </div>
                                      )}
                                      <div className="form-check form-check-inline">
                                        <input
                                          className="form-check-input form__radio-input"
                                          type="radio"
                                          name="inlineRadioOptions"
                                          id={`inlineRadio-${index}`}
                                          value={`option-${index}`}
                                          checked={selectedOption === index}
                                          onChange={() => {}}
                                        />
                                        <label
                                          className="form-check-label form__radio-label"
                                          htmlFor={`inlineRadio-${index}`}
                                        >
                                          <span
                                            className="form__radio-button"
                                            onClick={() =>
                                              handleRadioClick(index)
                                            }
                                          ></span>
                                        </label>
                                      </div>
                                      {element.modal_name !== null && (
                                        <div className="feedbackContainer">
                                          {element.ranking_status === 'U' ? (
                                            <span className="feedback-up active"></span>
                                          ) : (
                                            <span className={`feedback-up ${(altTextData.alt_text[index] === altText.id && upFeedback) ? 'active' : ''}`}
                                              onClick={(e: any) => {e.preventDefault, handleThumbsUp(element, index)}}>
                                            </span>
                                          )}
                                          {element.ranking_status === 'D' ? (
                                            <span className="feedback-down active"></span>
                                          ) : (
                                            <span className={`feedback-down ${(altTextData.alt_text[index] === altText.id && downFeedback) ? 'active' : ''}`}
                                              onClick={() => handleThumbsDown(element, index)}>
                                            </span>
                                          )}
                                        </div>
                                      )}
                                      <div
                                        className={`form-control-details form-control-details__${index} container-sm`}
                                        onClick={() => handleRadioClick(index)}
                                      >
                                        {element.modal}
                                      </div>
                                    </div>
                                  ) : (element.modal.includes(
                                      "Limit_reached"
                                    ) || element.modal === errorGPT4.errorDetails) ? (
                                    // Limit Reached for GPT Turbo
                                    <div className="limit-reached">
                                      <div className="engineOptions mt-2 ms-3">
                                        <span className="altText_1">
                                          {/* Option {index + 1} */}
                                          Alt Text
                                        </span>
                                        {/* {`${basePath}` !== "" && (
                                          <div
                                            className="engineName"
                                            data-tooltip={element.modal_name}
                                          >
                                            <i className="engine-icon"></i>
                                          </div>
                                        )} */}
                                      </div>
                                      <div
                                        className={`limit-error-details form-control-details form-control-details__${index} container-sm`}
                                      >
                                        {element.modal.includes(
                                          "Limit_reached"
                                        ) ? (
                                          <span className="errorDetails">
                                            {limitReached.errorDetails}
                                          </span>
                                        ) : (
                                          <span className="errorDetails">
                                            {errorGPT4.errorDetails}
                                          </span>
                                        )}
                                        {/* {limitReached.errorDetails} */}
                                      </div>
                                    </div>
                                  ) : element.modal_name.includes("GPT") && (
                                    <div>
                                      <span className="altText_1 mt-2 ms-3">
                                        {/* Option {index + 1} */}
                                        Alt Text
                                      </span>
                                      <span className="updating-alt-text mt-2 ms-3">
                                        Generating Alt Text...
                                      </span>
                                    </div>
                                  )}
                                </div>
                          )}
                        ) : localStorage.client_id === "7" && Array.from({ length: 1 }, (_, index) => (
                          <div key={index} className="altTextContainer_1 mb-3">
                            <span className="altText_1 mt-2 ms-3">
                              {/* Option {index + 1} */}
                              Alt Text
                            </span>
                            <span className="updating-alt-text mt-2 ms-3">
                              Generating Alt Text...
                            </span>
                          </div>
                        ))}

                        {altTextData.alt_text !== null && localStorage.client_id !== "7"
                          ? filteredAltText.map((element: any, index: number) => {
                                return (
                                  <div
                                    key={index}
                                    className="altTextContainer_1 mb-3"
                                  >
                                    {showUpdatingAltText &&
                                    (element.modal_name.includes(
                                      "BLIP with Context"
                                    ) ||
                                      element.modal_name.includes("GPT")) &&
                                    (altTextData.image_status === "P" ||
                                      altTextData.image_status === "I") ? (
                                      <>
                                        {/* <span className="altText_1 mt-2 ms-3">
                                          Option {index + 1}
                                        </span>
                                        <span className="updating-alt-text mt-2 ms-3">
                                          Generating Alt Text...
                                        </span> */}
                                        <div className="math-loader-container">
                                          <div className="math-progress-loader">
                                            <span className="loading-altText">
                                              Generating Alt Text...
                                            </span>
                                          </div>
                                        </div>
                                      </>
                                    ) : element.modal_name !== "" &&
                                      !element.modal.includes("Limit_reached") &&
                                      element.modal !== errorGPT4.errorDetails &&
                                      element.modal !== "is_math_image" ? (
                                      // Append all the model options excluding the ones with "Limit_reached" in the modal
                                      <div
                                        className={`form alt-text-1 ${
                                          selectedOption === index ? "active" : ""
                                        }`}
                                        // onClick={() => handleRadioClick(index)}
                                      >
                                        {mathModel ? (
                                          <div className="engineOptions mt-2 ms-3" onClick={() => handleRadioClick(index)}>
                                            <span className="altText_1 mathOption">
                                            {element.modal_name}
                                            </span>
                                          </div>
                                        ): (element.modal_name !== null &&
                                          <div className="engineOptions mt-2 ms-3" onClick={() => handleRadioClick(index)}>
                                            <span className="altText_1">
                                              Option {index + 1}
                                            </span>
                                            {`${basePath}` !== "" && (
                                              <div
                                                className="engineName"
                                                data-tooltip={element.modal_name}
                                              >
                                                <i className="engine-icon"></i>
                                              </div>
                                            )}
                                          </div>
                                        )}
                                        <div className="form-check form-check-inline">
                                          <input
                                            className="form-check-input form__radio-input"
                                            type="radio"
                                            name="inlineRadioOptions"
                                            id={`inlineRadio-${index}`}
                                            value={`option-${index}`}
                                            checked={selectedOption === index}
                                            onChange={() => {}}
                                          />
                                          <label
                                            className="form-check-label form__radio-label"
                                            htmlFor={`inlineRadio-${index}`}
                                          >
                                            <span
                                              className="form__radio-button"
                                              onClick={() =>
                                                handleRadioClick(index)
                                              }
                                            ></span>
                                          </label>
                                        </div>
                                        {element.modal_name !== null && (
                                          <div className="feedbackContainer">
                                          {element.ranking_status === 'U' ? (
                                            <span className="feedback-up active"></span>
                                          ) : (
                                            <span className={`feedback-up ${(altTextData.alt_text[index] === altText.id && upFeedback) ? 'active' : ''}`}
                                              onClick={(e: any) => {e.preventDefault, handleThumbsUp(element, index)}}>
                                            </span>
                                          )}
                                          {element.ranking_status === 'D' ? (
                                            <span className="feedback-down active"></span>
                                          ) : (
                                            <span className={`feedback-down ${(altTextData.alt_text[index] === altText.id && downFeedback) ? 'active' : ''}`}
                                              onClick={() => handleThumbsDown(element, index)}>
                                            </span>
                                          )}
                                        </div>
                                        )}
                                        <div
                                          className={`form-control-details form-control-details__${index} container-sm`}
                                          onClick={() => handleRadioClick(index)}
                                        >
                                          {/* {element.modal} */}
                                          {element.modal === "None of the above" ? 
                                            <span className="none-of-above-text">None of the above</span> : element.modal
                                          }
                                        </div>
                                      </div>
                                    ) : element.modal_name !== "" &&
                                      !element.modal.includes("Limit_reached") &&
                                      element.modal !== errorGPT4.errorDetails &&
                                      element.modal === "is_math_image" ? (
                                        isMathImageMessageDisplayed && element.modal_name === "LaTex Output" ? (
                                        <div key={index}>
                                          <div className="is-math-container">
                                            <div className="is-math">
                                              <span className="is-math-altText">
                                                Unable to process the image, this might not be a math image please select non math and try again
                                              </span>
                                            </div>
                                          </div>
                                        </div>
                                        ) : element.modal_name === "MathML Output" && element.modal_name === "Readable Output" && (
                                          <span></span>
                                        )
                                      ) : (element.modal.includes(
                                        "Limit_reached"
                                      ) || element.modal === errorGPT4.errorDetails) ? (
                                      // Limit Reached for GPT Turbo
                                      <div className="limit-reached">
                                        <div className="engineOptions mt-2 ms-3">
                                          <span className="altText_1">
                                            Option {index + 1}
                                          </span>
                                          {`${basePath}` !== "" && (
                                            <div
                                              className="engineName"
                                              data-tooltip={element.modal_name}
                                            >
                                              <i className="engine-icon"></i>
                                            </div>
                                          )}
                                        </div>
                                        <div
                                          className={`limit-error-details form-control-details form-control-details__${index} container-sm`}
                                        >
                                          {element.modal.includes(
                                            "Limit_reached"
                                          ) ? (
                                            <span className="errorDetails">
                                              {limitReached.errorDetails}
                                            </span>
                                          ) : (
                                            <span className="errorDetails">
                                              {errorGPT4.errorDetails}
                                            </span>
                                          )}
                                          {/* {limitReached.errorDetails} */}
                                        </div>
                                      </div>
                                    ) : (
                                      mathModel ? (
                                        <div>
                                          <span className="altText_1 mt-2 ms-3">
                                            {/* Option {index + 1} */}
                                            Model Name
                                          </span>
                                          <span className="updating-alt-text mt-2 ms-3">
                                            Generating Alt Text...
                                          </span>
                                        </div>
                                      ) : (
                                        // <div>
                                        //   <span className="altText_1 mt-2 ms-3">
                                        //     Option {index + 1}
                                        //   </span>
                                        //   <span className="updating-alt-text mt-2 ms-3">
                                        //     Generating Alt Text...
                                        //   </span>
                                        // </div>
                                        <div className="math-loader-container">
                                          <div className="math-progress-loader">
                                            <span className="loading-altText">
                                              Generating Alt Text...
                                            </span>
                                          </div>
                                        </div>
                                      )
                                    )}
                                  </div>
                                )
                              }
                            )
                          : localStorage.client_id !== "7" && 
                            <div className="math-loader-container">
                              <div className="math-progress-loader">
                                <span className="loading-altText">
                                  Generating Alt Text...
                                </span>
                              </div>
                            </div>
                          }
                          {altTextData.alt_text !== null && !isNoneOfAboveActive && altTextData.image_status !== ("P" || "I") &&
                          !isMathImageMessageDisplayed &&
                          <div className={`altTextContainer_1 mb-3 ${mathModel ? "mathModel_Nonclickable" : ""}`}>
                            <div
                              className={`form alt-text-1 none-of-above-option ${filteredAltText.length + 1} ${
                                selectedOption === filteredAltText.length + 1 ? "active" : ""
                              }`}>
                            <div className={`form-control-details form-control-details__${filteredAltText.length + 1}`}
                             onClick={() => handleRadioClick(filteredAltText.length + 1)}>
                                None of the above
                            </div>
                            <div className="form-check form-check-inline">
                              <input
                                className="form-check-input form__radio-input"
                                type="radio"
                                name="inlineRadioOptions"
                                id={`inlineRadio-${filteredAltText.length + 1}`}
                                value={`option-${filteredAltText.length + 1}`}
                                checked={selectedOption === filteredAltText.length + 1}
                                onChange={() => {}}
                              />
                              <label
                                className="form-check-label form__radio-label"
                                htmlFor={`inlineRadio-${filteredAltText.length + 1}`}
                              >
                                <span
                                  className="form__radio-button"
                                  onClick={() =>
                                    handleRadioClick(filteredAltText.length + 1)
                                  }
                                ></span>
                              </label>
                            </div>
                            <div
                              className={`form-control-details form-control-details__${filteredAltText.length + 1} container-sm none-of-above`}>
                              </div>
                            </div>
                          </div>
                        }
                        <div className="form-group formControlTextArea mt-4">
                          <label
                            htmlFor="exampleFormControlTextarea1"
                            className="altText_textArea mb-2"
                          >
                            <i className="human-icon"></i>
                            Select an Option to Customize Alt Text with Human Touch
                          </label>
                          <div className="custom-text-block">
                            <textarea
                              className="form-control"
                              id="exampleFormControlTextarea1"
                              value={
                                altTextData.image_status === "P" ||
                                altTextData.image_status === "I"
                                  ? ""
                                  : altText
                              }
                              onChange={handleAltTextChange}
                              disabled={altText === "No Alt-Text is Selected" || isMathImageMessageDisplayed ||
                              altTextData.image_status === "P" ||
                              altTextData.image_status === "I"}
                            ></textarea>
                          </div>
                        </div>
                        <div className="count-container mt-3">
                          <span className="label">Character Count :</span>
                          <span className="count ms-1">{characterCount}</span>
                        </div>
                        <div className="saveGroup">
                          {altTextData.image_status === "I" ||
                          altTextData.image_status === "P" ? (
                            <button
                              type="button"
                              className="btn btn-primary btn-lg mt-4 save-button disabled"
                              disabled
                            >
                              Save
                            </button>
                          ) 
                          : altTextData.image_status === "R" ? (
                            <button
                              type="button"
                              className="btn btn-primary btn-lg mt-4 save-button"
                              onClick={handleSave}
                              disabled={
                                !isTextAreaChanged || isMathImageMessageDisplayed
                              }
                            >
                              {/* {saving ? (
                                <div className="saving">
                                  <i className="saveIcon"></i>
                                  <span className="text">Approving</span>
                                </div>
                              ) : saved ? (
                                <span>Approved</span>
                              ) : (
                                <span>Approve</span>
                              )} */}
                              {saving ? (
                                <>
                                  <i className="saveIcon"></i>
                                  <span className="text">Saving</span>
                                </>
                              ) : saved ? (
                                <span>Save</span>
                              ) : (
                                <span>Saved</span>
                              )}
                            </button>
                          ) 
                          // : altTextData.image_status === "A" ? (
                          //   <button
                          //     type="button"
                          //     className="btn btn-primary btn-lg mt-4 save-button disabled"
                          //     disabled
                          //   >
                          //     Approved
                          //   </button>
                          // ) 
                          : (
                            <button
                              type="button"
                              className="btn btn-primary btn-lg mt-4 save-button"
                              onClick={handleSave}
                              disabled={(!isTextAreaChanged && selectedOption === null) ||
                                isMathImageMessageDisplayed
                              }
                            >
                              {saving ? (
                                <>
                                  <i className="saveIcon"></i>
                                  <span className="text">Saving</span>
                                </>
                              ) : saved ? (
                                <span>Saved</span>
                              ) : initialSave && (
                                <span>Save</span>
                              )}
                            </button>
                          )}
                        </div>
                        {/* <strong>This is the first item's accordion body.</strong> It is shown by default, until the collapse plugin adds the appropriate classes that we use to style each element. These classes control the overall appearance, as well as the showing and hiding via CSS transitions. You can modify any of this with custom CSS or overriding our default variables. It's also worth noting that just about any HTML can go within the <code>.accordion-body</code>, though the transition does limit overflow. */}
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                displayAltPage && (
                  <>
                    <div className="alt-text-title mt-3 mb-4">
                      Alt Text generated
                    </div>
                    {/* For Scholastic client_id 7 */}
                    {altTextData.alt_text !== null && localStorage.client_id === "7" ?
                      filteredAltText.map(
                        (element: any, index: number) => {
                          return (
                            <div
                              key={index}
                              className="altTextContainer_1 mb-3"
                            >
                              {showUpdatingAltText &&
                              (element.modal_name.includes("GPT")) &&
                              (altTextData.image_status === "P" ||
                                altTextData.image_status === "I") ? (
                                <>
                                  <span className="altText_1 mt-2 ms-3">
                                    Alt Text
                                  </span>
                                  <span className="updating-alt-text mt-2 ms-3">
                                    Generating Alt Text...
                                  </span>
                                </>
                              ) : element.modal_name !== "" &&
                                !element.modal.includes("Limit_reached") &&
                                element.modal_name.includes("GPT") &&
                                element.modal !== errorGPT4.errorDetails ? (
                                // Append all the model options excluding the ones with "Limit_reached" in the modal
                                <div
                                  className={`form alt-text-1 ${
                                    selectedOption === index ? "active" : ""
                                  }`}
                                  // onClick={() => handleRadioClick(index)}
                                >
                                  {element.modal_name !== null && (
                                    <div className="engineOptions mt-2 ms-3" onClick={() => handleRadioClick(index)}>
                                      <span className="altText_1">
                                        Alt Text
                                      </span>
                                      {/* {`${basePath}` !== "" && (
                                        <div
                                          className="engineName"
                                          data-tooltip={element.modal_name}
                                        >
                                          <i className="engine-icon"></i>
                                        </div>
                                      )} */}
                                    </div>
                                  )}
                                  <div className="form-check form-check-inline">
                                    <input
                                      className="form-check-input form__radio-input"
                                      type="radio"
                                      name="inlineRadioOptions"
                                      id={`inlineRadio-${index}`}
                                      value={`option-${index}`}
                                      checked={selectedOption === index}
                                      onChange={() => {}}
                                    />
                                    <label
                                      className="form-check-label form__radio-label"
                                      htmlFor={`inlineRadio-${index}`}
                                    >
                                      <span
                                        className="form__radio-button"
                                        onClick={() =>
                                          handleRadioClick(index)
                                        }
                                      ></span>
                                    </label>
                                  </div>
                                  {element.modal_name !== null && (
                                    <div className="feedbackContainer" onClick={() =>
                                      handleRadioClick(index)
                                    }>
                                      {element.ranking_status === 'U' ? (
                                        <span className="feedback-up active"></span>
                                      ) : (
                                        <span className={`feedback-up ${(altTextData.alt_text[index] === altText.id && upFeedback) ? 'active' : ''}`}
                                          onClick={(e: any) => {e.preventDefault, handleThumbsUp(element, index)}}>
                                        </span>
                                      )}
                                      {element.ranking_status === 'D' ? (
                                        <span className="feedback-down active"></span>
                                      ) : (
                                        <span className={`feedback-down ${(altTextData.alt_text[index] === altText.id && downFeedback) ? 'active' : ''}`}
                                          onClick={() => handleThumbsDown(element, index)}>
                                        </span>
                                      )}
                                    </div>
                                  )}
                                  <div
                                    className={`form-control-details form-control-details__${index} container-sm`}
                                    onClick={() => handleRadioClick(index)}
                                  >
                                    {element.modal}
                                  </div>
                                </div>
                              ) : (element.modal.includes(
                                  "Limit_reached"
                                ) || element.modal === errorGPT4.errorDetails) ? (
                                // Limit Reached for GPT Turbo
                                <div className="limit-reached">
                                  <div className="engineOptions mt-2 ms-3">
                                    <span className="altText_1">
                                      {/* Option {index + 1} */}
                                      Alt Text
                                    </span>
                                    {/* {`${basePath}` !== "" && (
                                      <div
                                        className="engineName"
                                        data-tooltip={element.modal_name}
                                      >
                                        <i className="engine-icon"></i>
                                      </div>
                                    )} */}
                                  </div>
                                  <div
                                    className={`limit-error-details form-control-details form-control-details__${index} container-sm`}
                                  >
                                    {element.modal.includes(
                                      "Limit_reached"
                                    ) ? (
                                      <span className="errorDetails">
                                        {limitReached.errorDetails}
                                      </span>
                                    ) : (
                                      <span className="errorDetails">
                                        {errorGPT4.errorDetails}
                                      </span>
                                    )}
                                    {/* {limitReached.errorDetails} */}
                                  </div>
                                </div>
                              ) : element.modal_name.includes("GPT") && (
                                <div>
                                  <span className="altText_1 mt-2 ms-3">
                                    {/* Option {index + 1} */}
                                    Alt Text
                                  </span>
                                  <span className="updating-alt-text mt-2 ms-3">
                                    Generating Alt Text...
                                  </span>
                                </div>
                              )}
                            </div>
                      )}
                    ) : localStorage.client_id === "7" && Array.from({ length: 1 }, (_, index) => (
                      <div key={index} className="altTextContainer_1 mb-3">
                        <span className="altText_1 mt-2 ms-3">
                          {/* Option {index + 1} */}
                          Alt Text
                        </span>
                        <span className="updating-alt-text mt-2 ms-3">
                          Generating Alt Text...
                        </span>
                      </div>
                    ))}

                    {altTextData.alt_text !== null && localStorage.client_id !== "7" 
                      ? filteredAltText.map(
                          (element: any, index: number) => {
                            return (
                              <div
                                key={index}
                                className={`altTextContainer_1 mb-3 ${mathModel ? "mathModel_Nonclickable" : ""}`}
                              >
                                {showUpdatingAltText && element.modal_name !== null &&
                                (element.modal_name.includes(
                                  "BLIP with Context"
                                ) ||
                                  element.modal_name.includes("GPT")) &&
                                (altTextData.image_status === "P" ||
                                  altTextData.image_status === "I") ? (
                                  <>
                                    {/* <span className="altText_1 mt-2 ms-3">
                                      Option {index + 1}
                                    </span>
                                    <span className="updating-alt-text mt-2 ms-3">
                                      Generating Alt Text...
                                    </span> */}
                                    <div className="math-loader-container">
                                      <div className="math-progress-loader">
                                        <span className="loading-altText">
                                          Generating Alt Text...
                                        </span>
                                      </div>
                                    </div>
                                  </>
                                ) : element.modal_name !== "" &&
                                  !element.modal.includes("Limit_reached") &&
                                  element.modal !== errorGPT4.errorDetails &&
                                  element.modal !== "is_math_image" ? (
                                    mathModel && element.modal_name === "MathML Output" ? (
                                      modalContent = expanded ? element.modal : `${element.modal.slice(0, 150)}... `
                                    ) : (
                                      modalContent = element.modal
                                    ),
                                  // Append all the model options excluding the ones with "Limit_reached" in the modal
                                  <div
                                    className={`form alt-text-1 ${
                                      (element.modal_name === "Readable Output" || element.modal_name === null) && selectedOption === index
                                       && mathModel ? "active" : selectedOption===index && !mathModel ? "active" : ""
                                    } ${mathModel ? "mathModel" : ""}`}
                                    // onClick={() => handleRadioClick(index)}
                                  >
                                    {mathModel ? (
                                      <div className="engineOptions mt-2 ms-3" onClick={() => handleRadioClick(index)}>
                                        <span className="altText_1 mathOption">
                                        {element.modal_name}
                                        </span>
                                      </div>
                                    ): (element.modal_name !== null &&
                                      <div className="engineOptions mt-2 ms-3" onClick={() => handleRadioClick(index)}>
                                        <span className="altText_1">
                                          Option {index + 1}
                                        </span>
                                        {`${basePath}` !== "" && (
                                          <div
                                            className="engineName"
                                            data-tooltip={element.modal_name}
                                          >
                                            <i className="engine-icon"></i>
                                          </div>
                                        )}
                                      </div>
                                    )}
                                    <div className="form-check form-check-inline">
                                      <input
                                        className="form-check-input form__radio-input"
                                        type="radio"
                                        name="inlineRadioOptions"
                                        id={`inlineRadio-${index}`}
                                        value={`option-${index}`}
                                        // checked={index === 2 && mathModel || selectedOption === index && !mathModel}
                                        checked={mathModel && (element.modal_name === "Readable Output" || element.modal_name === null) ? selectedOption === index
                                         : selectedOption === index && !mathModel}
                                        onChange={() => {}}
                                      />
                                      <label
                                        className="form-check-label form__radio-label"
                                        htmlFor={`inlineRadio-${index}`}
                                      >
                                        <span
                                          className="form__radio-button"
                                          onClick={() =>
                                            handleRadioClick(index)
                                          }
                                        ></span>
                                      </label>
                                    </div>
                                    {element.modal_name !== null && (
                                      <div className={`feedbackContainer ${mathModel ? "removeFeedback" : "" }`} onClick={() =>
                                        handleRadioClick(index)
                                      }>
                                      {element.ranking_status === 'U' ? (
                                        <span className="feedback-up active"></span>
                                      ) : (
                                        <span className={`feedback-up ${(altTextData.alt_text[index] === altText.id && upFeedback) ? 'active' : ''}`}
                                          onClick={(e: any) => {e.preventDefault, handleThumbsUp(element, index)}}>
                                        </span>
                                      )}
                                      {element.ranking_status === 'D' ? (
                                        <span className="feedback-down active"></span>
                                      ) : (
                                        <span className={`feedback-down ${(altTextData.alt_text[index] === altText.id && downFeedback) ? 'active' : ''}`}
                                          onClick={() => handleThumbsDown(element, index)}>
                                        </span>
                                      )}
                                    </div>
                                    )}
                                    <div
                                      className={`form-control-details form-control-details__${index} container-sm`}
                                      onClick={() => handleRadioClick(index)}
                                    >
                                      {modalContent === "None of the above" ? 
                                        <span className="none-of-above-text">None of the above</span> : modalContent
                                      }
                                      {!expanded && element.modal_name === "MathML Output" ? (
                                        <div onClick={toggleExpand} className="read-more-btn">
                                          Read More
                                        </div>
                                      ) : expanded && element.modal_name === "MathML Output" ? (
                                        <div onClick={toggleExpand} className="read-more-btn">
                                          <br />Read Less
                                        </div>
                                      ) : null}
                                    </div>
                                  </div>
                                ) : element.modal_name !== "" &&
                                  !element.modal.includes("Limit_reached") &&
                                  element.modal !== errorGPT4.errorDetails &&
                                  element.modal === "is_math_image" ? (
                                    isMathImageMessageDisplayed && element.modal_name === "LaTex Output" ? (
                                    <div key={index}>
                                      <div className="is-math-container">
                                        <div className="is-math">
                                          <span className="is-math-altText">
                                            Unable to process the image, this might not be a math image please select non math and try again
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                    ) : element.modal_name === "MathML Output" && element.modal_name === "Readable Output" && (
                                      <span></span>
                                    )
                                  ) : (element.modal.includes(
                                  "Limit_reached"
                                ) || element.modal === errorGPT4.errorDetails) ? (
                                  // Limit Reached for GPT Turbo
                                  <div className="limit-reached">
                                    <div className="engineOptions mt-2 ms-3">
                                      <span className="altText_1">
                                        Option {index + 1}
                                      </span>
                                      {`${basePath}` !== "" && (
                                        <div
                                          className="engineName"
                                          data-tooltip={element.modal_name}
                                        >
                                          <i className="engine-icon"></i>
                                        </div>
                                      )}
                                    </div>
                                    <div
                                      className={`limit-error-details form-control-details form-control-details__${index} container-sm`}
                                    >
                                      {element.modal.includes(
                                        "Limit_reached"
                                      ) ? (
                                        <span className="errorDetails">
                                          {limitReached.errorDetails}
                                        </span>
                                      ) : (
                                        <span className="errorDetails">
                                          {errorGPT4.errorDetails}
                                        </span>
                                      )}
                                      {/* {limitReached.errorDetails} */}
                                    </div>
                                  </div>
                                ) : (
                                  // Regenerte Alt Text for Math Model
                                  mathModel ? (
                                    <div className="math-loader-container">
                                      <div className="math-progress-loader">
                                        <span className="loading-altText">
                                          Generating Alt Text...
                                        </span>
                                      </div>
                                    </div>
                                  ) : (
                                    // <div>
                                    //   <span className="altText_1 mt-2 ms-3">
                                    //     Option {index + 1}
                                    //   </span>
                                    //   <span className="updating-alt-text mt-2 ms-3">
                                    //     Generating Alt Text...
                                    //   </span>
                                    // </div>
                                    <div className="math-loader-container">
                                      <div className="math-progress-loader">
                                        <span className="loading-altText">
                                          Generating Alt Text...
                                        </span>
                                      </div>
                                    </div>
                                  )
                                )}
                              </div>
                            )
                          }
                        )
                      : localStorage.client_id !== "7" &&
                        mathModel ? (
                          <>
                          <div className="math-loader-container">
                            <div className="math-progress-loader">
                              <span className="loading-altText">
                                Generating Alt Text...
                              </span>
                            </div>
                          </div>
                          </>
                        ) : (
                          // <div
                          //   key={index}
                          //   className="altTextContainer_1 mb-3"
                          // >
                          //   <span className="altText_1 mt-2 ms-3">
                          //     Option {index + 1}
                          //   </span>
                          //   <span className="updating-alt-text mt-2 ms-3">
                          //     Generating Alt Text...
                          //   </span>
                          // </div>
                          <>
                          <div className="math-loader-container">
                            <div className="math-progress-loader">
                              <span className="loading-altText">
                                Generating Alt Text...
                              </span>
                            </div>
                          </div>
                          </>
                        )}
                      {altTextData.alt_text !== null && !isNoneOfAboveActive && altTextData.image_status !== ("P" || "I") &&
                      !isMathImageMessageDisplayed &&
                        <div className={`altTextContainer_1 mb-3 ${mathModel ? "mathModel_Nonclickable" : ""}`}>
                          <div
                            className={`form alt-text-1 none-of-above-option ${filteredAltText.length + 1} ${
                              selectedOption === filteredAltText.length + 1 ? "active" : ""
                            }`}>
                          <div className={`form-control-details form-control-details__${filteredAltText.length + 1}`}
                           onClick={() => handleRadioClick(filteredAltText.length + 1)}>
                              None of the above
                          </div>
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input form__radio-input"
                              type="radio"
                              name="inlineRadioOptions"
                              id={`inlineRadio-${filteredAltText.length + 1}`}
                              value={`option-${filteredAltText.length + 1}`}
                              checked={selectedOption === filteredAltText.length + 1}
                              onChange={() => {}}
                            />
                            <label
                              className="form-check-label form__radio-label"
                              htmlFor={`inlineRadio-${filteredAltText.length + 1}`}
                            >
                              <span
                                className="form__radio-button"
                                onClick={() =>
                                  handleRadioClick(filteredAltText.length + 1)
                                }
                              ></span>
                            </label>
                          </div>
                          <div
                            className={`form-control-details form-control-details__${filteredAltText.length + 1} container-sm none-of-above`}
                          ></div>
                          </div>
                        </div>
                      }
                    <div className="form-group formControlTextArea mt-4">
                      <label
                        htmlFor="exampleFormControlTextarea1"
                        className="altText_textArea mb-2"
                      >
                        <i className="human-icon"></i>
                        Select an Option to Customize Alt Text with Human Touch
                      </label>
                      <div className="custom-text-block">
                        <textarea
                          className="form-control"
                          id="exampleFormControlTextarea1"
                          value={
                            altTextData.image_status === "P" ||
                            altTextData.image_status === "I"
                              ? ""
                              : altText
                          }
                          onChange={handleAltTextChange}
                          disabled={altText === "No Alt-Text is Selected" || isMathImageMessageDisplayed ||
                          altTextData.image_status === "P" ||
                          altTextData.image_status === "I"
                          }
                        ></textarea>
                      </div>
                    </div>
                    <div className="count-container mt-3">
                      <span className="label">Character Count :</span>
                      <span className="count ms-1">{characterCount}</span>
                    </div>
                    <div className="saveGroup">
                      {altTextData.image_status === "I" ||
                      altTextData.image_status === "P" ? (
                        <button
                          type="button"
                          className="btn btn-primary btn-lg mt-4 save-button disabled"
                          disabled
                        >
                          Save
                        </button>
                      ) 
                      : altTextData.image_status === "R" ? (
                        <button
                          type="button"
                          className="btn btn-primary btn-lg mt-4 save-button"
                          onClick={handleSave}
                          disabled={
                            !isTextAreaChanged || isMathImageMessageDisplayed
                          }
                        >
                          {/* {saving ? (
                            <div className="saving">
                              <i className="saveIcon"></i>
                              <span className="text">Approving</span>
                            </div>
                          ) : saved ? (
                            <span>Approved</span>
                          ) : (
                            <span>Approve</span>
                          )} */}
                          {saving ? (
                            <>
                              <i className="saveIcon"></i>
                              <span className="text">Saving</span>
                            </>
                          ) : saved ? (
                            <span>Save</span>
                          ) : (
                            <span>Saved</span>
                          )}
                        </button>
                      ) 
                      // : altTextData.image_status === "A" ? (
                      //   <button
                      //     type="button"
                      //     className="btn btn-primary btn-lg mt-4 save-button disabled"
                      //     disabled
                      //   >
                      //     Approved
                      //   </button>
                      // ) 
                      : (
                        <button
                          type="button"
                          className="btn btn-primary btn-lg mt-4 save-button"
                          onClick={handleSave}
                          disabled={(!isTextAreaChanged && selectedOption === null) ||
                            isMathImageMessageDisplayed
                          }
                        >
                          {saving ? (
                            <>
                              <i className="saveIcon"></i>
                              <span className="text">Saving</span>
                            </>
                          ) : saved ? (
                            <span>Saved</span>
                          ) : initialSave && (
                            <span>Save</span>
                          )}
                        </button>
                      )}
                    </div>
                  </>
                )
              )}
            </div>
          </div>
          )}
        </div>

      {/* Modal for Enlarged Image */}
      <div
        className={`modal ${showModal ? "show" : ""}`}
        tabIndex={-1}
        role="dialog"
        style={{ display: showModal ? "block" : "none" }}
      >
        <div className="modal-dialog modal-dialog-centered modal-lg">
          <div className="modal-content">
            <div className="modal-body">
              <div className="preview-image mb-3">
                <span>Image Preview</span>
                <span onClick={handleModalToggle}>
                  <i className="close-icon"></i>
                </span>
              </div>
              <div>
                <img
                  src={altTextData.original_file_path}
                  alt={altTextData.file_name}
                  className="img-fluid enlarged-image"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AltTextDetails
