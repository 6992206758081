import React, { useEffect, useRef, useState } from "react"
import { ReactReader } from "react-reader"
import indexedDBService from "../../util/db"
const EPUBViewer = ({ parentFilePath, pdfPageNumber }: any) => {
  const isMounted = useRef(true)
  const [epubBytesArray, setEpubBytesArray] = useState<any>()
  const [location, setLocation] = useState<string | number>(pdfPageNumber)
  useEffect(() => {
    return () => {
      isMounted.current = false
    }
  }, [])
  // useEffect(() => {
  //   setLocation(pdfPageNumber)
  // }, [pdfPageNumber])
  useEffect(() => {
    if (isMounted) {
      indexedDBService.getPDFFromDB(parentFilePath).then((blob: any) => {
        if (blob) {
          setEpubBytesArray(new Blob([blob], { type: "application/epub+zip" }))
        } else {
          let intervalId = setInterval(() => {
            indexedDBService
              .getPDFFromDB(parentFilePath)
              .then((updatedBlob: any) => {
                if (updatedBlob) {
                  setEpubBytesArray(
                    new Blob([updatedBlob], { type: "application/epub+zip" })
                  )
                  clearInterval(intervalId) // Cancel the interval when data is found
                }
              })
          }, 15000) // Check every 15 seconds
        }
      })
    }
  }, [parentFilePath])
  console.log(pdfPageNumber, "DEBUG: pdfPageNumber")
  return (
    <div style={{ height: "80vh" }}>
      {epubBytesArray !== undefined && (
        <ReactReader
          url={epubBytesArray}
          location={pdfPageNumber - 1}
          locationChanged={(epubcfi: string) => setLocation(epubcfi)}
        />
      )}
    </div>
  )
}
export default EPUBViewer