import React from "react"
import ImageTable from "../ImageTable/imageTable"
import { imageDataProps } from "./interface"

const Dashboard = (props: imageDataProps) => {
  return (
        <>
            <ImageTable key={props.randomKey}/>
        </>
  )
}

export default Dashboard
