import React, { useState } from "react"
import { Navigate } from "react-router-dom"
import axios from "axios"

const basePath = process.env.REACT_APP_BASE_PATH
export const ProtectedRoute = ({ children }: any) => {
  const userToken = localStorage.getItem("user-token")
  return (
    <>
      {userToken ? (
        children
      ) : (
        <Navigate to={`${basePath}` !== "" ? `${basePath}` : `/`} />
      )}
    </>
  )
}

export const PublicRoute = ({ children }: any) => {
  const [isAdminLogin, setIsAdminLogin] = useState(false) // check Admin Login
  const [navigation, setNavigation] = useState("")
  const [userToken, setUserToken] = useState<string | null | undefined>(
    localStorage.getItem("user-token")
  )
  const apiBasePath = process.env.REACT_APP_API_URL

  if (userToken !== null)
    axios
      .get(`${apiBasePath}api/admin/checkUsersRoleData`, {
        headers: {
          Authorization: `Bearer ${userToken}`
        }
      })
      .then((response) => {
        // console.log(response.data)

        const data = response.data.data
        if (response.status === 200) {
          // console.log(data.usertype, "usertype")
          if (
            data.usertype &&
            (data.usertype === "Admin" || data.usertype === "Super admin")
          ) {
            setIsAdminLogin(true)
            setNavigation("Upload")
          } else {
            setIsAdminLogin(false)
            setNavigation("Dashboard")
          }
        }
      })
      .catch((error) => {
        localStorage.clear()
        setUserToken(null)
      })
  return (
    <>{!userToken ? children : <Navigate to={`${basePath}/${navigation}`} />}</>
  )
}
