import React, { memo, useEffect, useMemo, useRef, useState } from "react"
import { Viewer } from "@react-pdf-viewer/core"
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout"
import { pageNavigationPlugin } from "@react-pdf-viewer/page-navigation"
import { zoomPlugin } from "@react-pdf-viewer/zoom"
import "@react-pdf-viewer/core/lib/styles/index.css"
import "@react-pdf-viewer/default-layout/lib/styles/index.css"
import "@react-pdf-viewer/toolbar/lib/styles/index.css"
import * as pdfjs from "pdfjs-dist"
import pdfjsWorker from "pdfjs-dist/build/pdf.worker.entry"
// import blobToUint8Array from "../../util/converter"
import indexedDBService from "../../util/db"
import "./pdf.scss"
// import { Worker } from "@react-pdf-viewer/core"
// import { Document } from "react-pdf"

pdfjs.GlobalWorkerOptions.workerSrc = pdfjsWorker

const PDFViewer = ({ parentFilePath, pdfPageNumber }: any) => {
  const [pdfBytesArray, setPdfBytesArray] = useState<any>([])

  const [isLoading, setIsLoading] = useState<boolean>(true)
  const isMounted = useRef(true)
  const initialPage = pdfPageNumber - 1
  // Create the zoom plugin instance
  const zoomPluginInstance = zoomPlugin()
  const { Zoom } = zoomPluginInstance
  const renderToolbar = (Toolbar: any) => (
    <Toolbar>
      {(slots: any) => {
        const {
          CurrentPageInput,
          CurrentPageLabel,
          Download,
          GoToNextPage,
          GoToPreviousPage,
          NumberOfPages,
          Zoom,
          ZoomIn,
          ZoomOut
        } = slots

        // const { GoToNextPage, GoToPreviousPage } = navigationPlugin

        return (
          <div
            style={{
              alignItems: "center",
              display: "flex",
              width: "100%",
              fontFamily: "Helvetica"
            }}
          >
            <div style={{ padding: "0px 2px" }}>
              <GoToPreviousPage />
            </div>
            <div
              style={{
                padding: "0px 2px",
                display: "flex",
                alignItems: "center"
              }}
            >
              <CurrentPageInput />/
              <span style={{ marginLeft: "3px" }}>
                <NumberOfPages />
              </span>
            </div>
            <div style={{ padding: "0px 2px" }}>
              <GoToNextPage />
            </div>
            <div style={{ padding: "0px 2px", marginLeft: "auto" }}>
              <ZoomOut />
            </div>
            <div className="zoomInput" style={{ padding: "0px 2px" }}>
              <Zoom levels={[0.8, 1, 1.2, 1.6, 2.4, 3.2]} />
            </div>
            <div style={{ padding: "0px 2px", marginRight: "20px" }}>
              <ZoomIn />
            </div>
          </div>
        )
      }}
    </Toolbar>
  )
  const defaultLayoutPluginInstance = defaultLayoutPlugin({
    sidebarTabs: (defaultTabs) => [],
    renderToolbar
  })

  const navigationPlugin = pageNavigationPlugin()
  // const test = useMemo(() => {
  //   if (!isLoading) {
  //     return (
  //       <Viewer
  //         fileUrl={pdfBytesArray.map((item: any) => item)}
  //         // plugins={[defaultLayoutPluginInstance, navigationPlugin]}
  //         initialPage={initialPage} // Set the initial page here
  //         defaultScale={0.7}
  //       />
  //     )
  //   }
  // }, [parentFilePath])

  useEffect(() => {
    return () => {
      console.log("UNMOUNT CALLED")
      isMounted.current = false
    }
  }, [])

  useEffect(() => {
    navigationPlugin.jumpToPage(pdfPageNumber - 1)
  }, [pdfPageNumber])

  useEffect(() => {
    if (isMounted) {
      console.log("DEBUG: FETCHING PDF FROM DB")
      indexedDBService.getPDFFromDB(parentFilePath).then((blob: any) => {
        if (blob) {
          // blobToUint8Array(blob).then((value) => {
          console.log(blob)
          // setPdfBytesArray(new Uint8Array([...blob]))
          setPdfBytesArray(new Uint8Array(blob))

          // const b = new Blob([blob], { type: "application/pdf" })
          // const objectUrl = URL.createObjectURL(b)
          // console.log("printing objkect url: " + objectUrl)
          // Set the object tag's data attribute to the object URL
          // setUrl(objectUrl)
          // document.querySelector("object")?.setAttribute("data", objectUrl)
          // setCopyPdfBytesArray(new Uint8Array(blob))
          setIsLoading(false)
          navigationPlugin.jumpToPage(pdfPageNumber - 1)
          // })
        } else {
          let intervalId = setInterval(() => {
            console.log("DEBUG: set interval fetch called")
            indexedDBService
              .getPDFFromDB(parentFilePath)
              .then((updatedBlob: any) => {
                if (updatedBlob) {
                  console.log("DEBUG: found result in cache")
                  // blobToUint8Array(updatedBlob).then((value) => {
                  setPdfBytesArray(new Uint8Array(updatedBlob))
                  setIsLoading(false)
                  navigationPlugin.jumpToPage(pdfPageNumber - 1)
                  // })
                  console.log("DEBUG: clearing interval")
                  clearInterval(intervalId) // Cancel the interval when data is found
                }
              })
          }, 15000) // Check every 15 seconds
        }
      })
    }
  }, [parentFilePath])

  console.log(pdfPageNumber, "pdfPageNumber")

  return (
    <div className="container">
      <div className="pdf-container">
        {isLoading ? (
          <div className="loader-container">
            <div className="custom-content-loader" />
          </div>
        ) : pdfBytesArray.length >= 0 && isMounted ? (
          <Viewer
            fileUrl={pdfBytesArray}
            plugins={[defaultLayoutPluginInstance, navigationPlugin]}
            initialPage={initialPage} // Set the initial page here
            defaultScale={0.7}
          />
        ) : (
          <p>Error</p>
        )}
      </div>
    </div>
  )
}

// export default memo(PDFViewer, (prev, next) => {
//   return prev.parentFilePath === next.parentFilePath
// })

export default PDFViewer
